import React from 'react';

import './RegisterCallout.css';
import '../Callout.css';

const RegisterCallout = (props) => {
    let content = props.content;
    if (props.learnmorelink) {
        content += `<a href="${props.learnmorelink}" target="_blank" rel="noopener noreferrer"><button class="LearnMoreLink" type="button">Learn more</button></a>`
    }
    return(
        <div className="RegisterCallout Callout">
            <div className="Callout-Image"><img src={props.image} alt="Webinar"/></div>
            <div className="Callout-Content" dangerouslySetInnerHTML={{__html: content}} style={{color: props.textcolor, backgroundColor: props.backgroundcolor}} />
        </div>
    )
}
export default RegisterCallout;