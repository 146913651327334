import React from 'react';

import GreenDropdownArrow from '../../../../images/qa/dropdown-green.svg';
import WhiteDropdownArrow from '../../../../images/qa/dropdown-white.svg';

import './QAQuestion.css';

const QAQuestion = (props) =>
    <div className={`QAQuestion ${props.expanded ? "expand" : ""}`}>
        <div className="QuestionHeader" onClick={props.handleClick}>
            <h3 dangerouslySetInnerHTML={{__html: props.question}} />
            <div className={`QuestionArrow ${props.expanded ? "flipped" : ""}`} style={{backgroundImage: `url("${props.expanded ? WhiteDropdownArrow : GreenDropdownArrow}")`}} />
        </div>
        <div className={`QAQuestionContent ${props.expanded ? "" : "hidden"}`}>
            { props.author 
                ? <h3>{props.author}:</h3>
                : null
            }
            <p dangerouslySetInnerHTML={{__html: props.answer}} />
        </div>
    </div>

export default QAQuestion;