import React, { Component } from 'react';
//import ReactGA from 'react-ga';
import { Redirect } from 'react-router-dom'

import Questionaire from '../../Questionaire/Questionaire';
import RegisterCallout from '../../Callout/RegisterCallout/RegisterCallout';

import YouTubeImg from '../../../images/callouts/youtube.png';
import TwitterImg from '../../../images/callouts/twitter.png';
import WebinarFAQ from '../../../resources/Documents/Webinar_FAQ_Troubleshooting_Guide.pdf';
import DownloadMAC from '../../../images/technical/desktop_mac.png';
import DownloadWINDOWS from '../../../images/technical/desktop_windows.png';
import DownloadAPPSTORE from '../../../images/technical/mobile_apple.png';
import DownloadGOOGLESTORE from '../../../images/technical/mobile_google.png';
import RegisteredCheck from '../../../images/register/registered.png';
import TwitterShareImg from '../../../images/share/twitter.png';
import EmailShareImg from '../../../images/share/email.png';
import CalendarShareImg from '../../../images/share/calendar.png';
import './WebinarPage.css';
import { baseURL } from '../../../constants/api';


class WebinarPage extends Component {
    
    constructor(props){
        super(props);

        this.state = {
            registered: (localStorage.getItem(this.props.page.CONFIG_CampaignCode) !== null),
            countdown: this.props.page.CONFIG_EpochStartTime - ((new Date()).getTime() / 1000),
            AdobeConnectName: localStorage.getItem(this.props.page.CONFIG_CampaignCode)
        }

        this.heroImages = require.context('../../../resources/Images/Hero');
        this.bottomCalloutImages = require.context('../../../resources/Images/BottomCallout');
        this.surveyQuestions = require.context('../../../resources/SurveyQuestions');
        this.calendarEvents = require.context('../../../resources/CalendarEvents');
        this.ProgressBarRef = React.createRef();
    }

    parseQuery = (param) => {
        let returnVal = null;

        if (window.location.search.length > 0) {
            (window.location.search[0] === '?' ? window.location.search.substr(1) : window.location.search).split('&').map((pair) => {
                if (pair.split('=')[0] === param)
                    returnVal = (pair.split('=').length > 1 ? pair.split('=')[1] : "true");
                return true;
            })
        }

        return returnVal;
    }

    componentDidMount() {
        // ReactGA.pageview(`BASF | ${this.props.page.CONFIG_TopNavName} - Webinar`);
        document.title = `BASF | ${this.props.page.CONFIG_TopNavName} - Webinar`;
        this.WebinarCountdown = setInterval(() => {
            this.setState({countdown: this.props.page.CONFIG_EpochStartTime - ((new Date()).getTime() / 1000)})
        }, 1000);
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        clearInterval(this.WebinarCountdown);
    }

    generateCountdownString = () => {
        let days = Math.max(0, Math.floor(this.state.countdown / 86400));
        let hours = Math.max(0, Math.floor((this.state.countdown - (days * 86400)) / 3600 ));
        let minutes = Math.max(0, Math.floor((this.state.countdown - (days * 86400) - (hours * 3600)) / 60));
        let seconds = Math.max(0, Math.floor(this.state.countdown - (days * 86400) - (hours * 3600) - (minutes * 60)));

        return `${days} Days, ${hours} Hours, ${minutes} Minutes, ${seconds} Seconds`;
    }

    renderProgressBar = () => {
        if (Math.abs(this.state.countdown) < 7200) {
            return (
                <div className="RegisterToggle" ref={this.ProgressBarRef}>
                    <div className="reg-inprogress black-on-orange">WEBINAR IN PROGRESS</div>
                </div>
            );
        }

        return (
            <div className="RegisterToggle" ref={this.ProgressBarRef}>
                <div className={`reg-toggle ${(!this.state.registered) ? "white-on-black" : "grey-on-grey"}`}>Registration</div>
                <div className={`reg-toggle ${(this.state.registered) ? "white-on-black" : "grey-on-grey"}`}>Confirmation</div>
            </div>
        );
    }

    renderDetailsSection = () => {
        if (Math.abs(this.state.countdown) < 7200) {
            return (
                <div className="WebinarInfo flex">
                    <div className="half-div">
                        <div className="table">
                            <div className="Countdown table-cell">
                                <h1>{this.props.page.CONFIG_Name}&nbsp;{this.props.page.CONFIG_Name.length >= 7 && this.props.page.CONFIG_Name.slice(-7) === "Webinar" ? "" : "Webinar"}</h1>
                                <p>The webinar takes place in:</p>
                                {this.generateCountdownString()}
                            </div>
                        </div>
                    </div>
                    <div className="half-div flex">
                        <div className="half-div black-on-grey">
                            <h2>Already Registered?</h2>
                            <p>If you have already registered for the webinar, then please join the webinar from the email invitation that we have sent to your email. Please do not register again.</p>
                        </div>
                        <div className="half-div white-on-black">
                            <h2>New Registration</h2>
                            <p>If you have not registered to the webinar yet, then please proceed by submitting your information below and then click on "Join Webinar" on the next page.</p>
                            {/*<p>If you have not registered to the webinar yet, then please proceed by submitting your information below.</p>*/}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="WebinarInfo">
                <h1 className="main_message">{this.props.page.COPY_MainHeader}</h1>
                <div className="WebinarTimer">
                    <span>The webinar takes place in:</span>
                    <div className="Countdown">{this.generateCountdownString()}</div>
                </div>
                <div className="WebinarDetails">
                    <div className="WebinarDescription" dangerouslySetInnerHTML={{__html: this.props.page.COPY_Details}} />
                </div>
            </div>
        );
    }


    joinWebinar =  async(e) => {
		e.preventDefault();
		const fetchURL=`${baseURL}/getwebinarlink?adobeid=${this.state.AdobeConnectName.replace(/#/g, '%23').replace(/ /g, '%20')}&venuecode=${this.props.page.CONFIG_CampaignCode}`;
		let webinarLink;


        try {
			await fetch(fetchURL)
			.then(function(response) {
				return response.json().then(function(response) {
                    webinarLink=response.data[0].link
				});
			});

			window.open(
				webinarLink,
				'_blank'
			);

		} 
		catch(err) {
			alert(err);
		}
	};

    renderSubmissionSection = () => {
        if (Math.abs(this.state.countdown) < 7200) {
            return (
                <div className="SubmissionSection">
                    <img className="register-check" src={RegisteredCheck} alt="Registered" />
                    <h2>Thank you! You're now registered!</h2>
                    {/*<h2>Your information has been successfully submitted!</h2>*/}
                    {/*<p>Your link to join the webinar has been sent to your email.</p>*/}
                    {/*<a href={`${this.props.page.RES_WebinarLink}${this.state.AdobeConnectName === null ? '' : `?guestName=${this.state.AdobeConnectName.replace(/#/g, '%23').replace(/ /g, '%20')}`}`}><button className="joinnow-button" type="button">Join Webinar</button></a>*/}
                    <button className="joinnow-button" type="button" onClick={this.joinWebinar}>Join Webinar</button>
                </div>
            );
        }

        return (
            <div className="SubmissionSection">
                <img className="register-check" src={RegisteredCheck} alt="Registered" />
                <h2>Thank you! You're now registered!</h2>
                <p>A confirmation email is on its way.</p>
                <p>Note: You'll receive a link to the live webinar on the day of the webinar.</p>
                <div>
                    <div className="ShareContainer">
                        <div className="ShareContainer-Child"><a href={`mailto:?Subject=${this.props.page.COPY_ShareEmailSubject}&body=${this.props.page.COPY_ShareEmailBody}`}><img src={EmailShareImg} alt="email" /></a></div>
                        <p className="ShareContainer-Child">Know someone else who is interested in learning more about this webinar?<br />Click on the mail icon to share this event with them.</p>
                    </div>
                    {<div className="ShareContainer">
                        <div className="ShareContainer-Child"><a href={this.calendarEvents(`./${this.props.page.CONFIG_EventCode}.ics`)} download="Webinar.ics"><img src={CalendarShareImg} alt="calendar" /></a></div>
                        <p className="ShareContainer-Child">Don't miss the webinar!<br />Click on the calendar icon to add the event to your calendar.</p>
                    </div>}
                    <div className="ShareContainer">
                        <div className="ShareContainer-Child"><a href={`https://twitter.com/intent/tweet?text=${this.props.page.COPY_ShareTwitter}`} target="_blank" rel="noopener noreferrer"><img src={TwitterShareImg} alt="twitter" /></a></div>
                        <p className="ShareContainer-Child">Share via Twitter<br />Click on the share icon to share on Twitter.</p>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        if  (this.state.countdown <= -7200)
            return <Redirect to='/' />
        
        else if (this.parseQuery("media") === "true") {
            return (
                <div className={`WebinarPage ${this.props.stickyTopNav ? "stickyPageMargin" : ""}`}>
                    <img className="HeroImage" src={this.heroImages(`./${this.props.page.CONFIG_EventCode}.jpg`)} alt={this.props.page.CONFIG_Name} />
{                   (Math.abs(this.state.countdown) < 7200)
                    ?
                    <React.Fragment>
                        {this.renderProgressBar()}
                        <div className="SubmissionSection">
                            {/*<h2>Click the button below to join the webinar!</h2>
                            <a href={this.props.page.RES_WebinarLink}><button className="joinnow-button" type="button">Join Webinar</button></a>*/}
                            <p>Your link to join the webinar has been sent to your email.</p>
                        </div>
                    </React.Fragment>
                    :
                    this.renderDetailsSection()
}                   {/*<div className="WebinarTechnical">
                        <p>Looking for technical assistance on how to join the webinar? We recommend Google Chrome for ease of use. Be sure to disable your browser’s pop-up blocker to join the webinar. For additional assistance, please review the FAQ and troubleshooting guide below.</p>
                        <a href={WebinarFAQ} target="_blank" rel="noopener noreferrer"><button type="button" className="FAQ-Button">FAQ & Troubleshooting Guide</button></a>
                        <p>Joining the webinar on desktops or laptops is highly recommended for best optimal user experience. All users are encouraged to download the Adobe Connect application prior to joining to ensure ease of use and convenience. You can download the application for your device using one of the download links provide below.</p>
                        <h3>For desktop users</h3>
                        <div className="WebinarApps">
                            <a href="http://www.adobe.com/go/ConnectSetupMac"><img className="Download-Button" src={DownloadMAC} alt="Download MAC" /></a>
                            <a href="http://www.adobe.com/go/Connectsetup"><img className="Download-Button" src={DownloadWINDOWS} alt="Download WINDOWS" /></a>
                        </div>
                        <h3>For mobile users</h3>
                        <div className="WebinarApps">
                            <a href="https://itunes.apple.com/ca/app/adobe-connect/id430437503?mt=8" target="_blank" rel="noopener noreferrer"><img className="Download-Button" src={DownloadAPPSTORE} alt="Download APPSTORE" /></a>
                            <a href="https://play.google.com/store/apps/details?id=air.com.adobe.connectpro&hl=en_CA" target="_blank" rel="noopener noreferrer"><img className="Download-Button" src={DownloadGOOGLESTORE} alt="Download GOOGLESTORE" /></a>
                        </div>
                    </div>*/}
                    {this.props.page.CONFIG_BottomCallout
                    ? <RegisterCallout content={this.props.page.COPY_BottomCalloutContent} image={this.bottomCalloutImages(`./${this.props.page.CONFIG_EventCode}.jpg`)} textcolor={this.props.page.STYLE_BottomCalloutTextColor} backgroundcolor={this.props.page.STYLE_BottomCalloutBackgroundColor} learnmorelink={this.props.page.RES_BottomCalloutLink} />
                    : null
                    }
                    
                    <div className="WebinarCallouts flex">
                        <div className="Callout-Half white-on-green">
                            <div className="Default-Callout-Content">
                                <h2>Talk to us. We're here for&nbsp;you.</h2>
                                <p>Want to chat? One of our live agents is ready to answer your&nbsp;questions.</p>
                            </div>
                        </div>
                        <div className="Callout-Half darkgrey-on-grey">
                            <div className="Default-Callout-Content">
                                <h2>Got any questions?</h2>
                                <p>Call our team of experts at 1-877-371-BASF (2273) to reach AgSolutions<sup>®</sup> Customer&nbsp;Care.</p>
                                <a href="https://twitter.com/basfagsolutions" target="_blank" rel="noopener noreferrer"><img className="socialmedia-img" src={TwitterImg} alt="Twitter" /></a>
                                <a href="http://www.youtube.com/user/BASFAgSolutions" target="_blank" rel="noopener noreferrer"><img className="socialmedia-img" src={YouTubeImg} alt="YouTube" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={`WebinarPage ${this.props.stickyTopNav ? "stickyPageMargin" : ""}`}>
                <img className="HeroImage" src={this.heroImages(`./${this.props.page.CONFIG_EventCode}.jpg`)} alt={this.props.page.CONFIG_Name} />
                {this.renderProgressBar()}
                {this.state.registered ? this.renderSubmissionSection() : this.renderDetailsSection()}
                {this.state.registered ? null : <Questionaire WebinarLink={this.props.page.RES_WebinarLink} isLegacy={this.props.page.CONFIG_Legacy} campaign_code={this.props.page.CONFIG_CampaignCode} event_code={this.props.page.CONFIG_EventCode} includeSurvey={!(Math.abs(this.state.countdown) < 7200)} attended={Math.abs(this.state.countdown) < 7200} questions={this.surveyQuestions(`./${this.props.page.CONFIG_EventCode}.json`)} handleSubmit={(name) => this.setState({registered: true, AdobeConnectName: name}, () => {window.scrollTo(0, this.ProgressBarRef.current.offsetTop)})}/>}

                {/*<div style={{'textAlign':'center','marginTop':'4rem'}}>
                  <h2>Sorry! Registration is closed now</h2>
                 </div> */}

                {/*<div className="WebinarTechnical">
                    <p>Looking for technical assistance on how to join the webinar? We recommend Google Chrome for ease of use. Be sure to disable your browser’s pop-up blocker to join the webinar. For additional assistance, please review the FAQ and troubleshooting guide below.</p>
                    <a href={WebinarFAQ} target="_blank" rel="noopener noreferrer"><button type="button" className="FAQ-Button">FAQ & Troubleshooting Guide</button></a>
                    <p>Joining the webinar on desktops or laptops is highly recommended for best optimal user experience. All users are encouraged to download the Adobe Connect application prior to joining to ensure ease of use and convenience. You can download the application for your device using one of the download links provided below.</p>
                    <h3>For desktop users</h3>
                    <div className="WebinarApps">
                        <a href="http://www.adobe.com/go/ConnectSetupMac"><img className="Download-Button" src={DownloadMAC} alt="Download MAC" /></a>
                        <a href="http://www.adobe.com/go/Connectsetup"><img className="Download-Button" src={DownloadWINDOWS} alt="Download WINDOWS" /></a>
                    </div>
                    <h3>For mobile users</h3>
                    <div className="WebinarApps">
                        <a href="https://itunes.apple.com/ca/app/adobe-connect/id430437503?mt=8" target="_blank" rel="noopener noreferrer"><img className="Download-Button" src={DownloadAPPSTORE} alt="Download APPSTORE" /></a>
                        <a href="https://play.google.com/store/apps/details?id=air.com.adobe.connectpro&hl=en_CA" target="_blank" rel="noopener noreferrer"><img className="Download-Button" src={DownloadGOOGLESTORE} alt="Download GOOGLESTORE" /></a>
                    </div>
                </div>*/}
                {this.props.page.CONFIG_BottomCallout
                ? <RegisterCallout content={this.props.page.COPY_BottomCalloutContent} image={this.bottomCalloutImages(`./${this.props.page.CONFIG_EventCode}.jpg`)} textcolor={this.props.page.STYLE_BottomCalloutTextColor} backgroundcolor={this.props.page.STYLE_BottomCalloutBackgroundColor} learnmorelink={this.props.page.RES_BottomCalloutLink} />
                : null
                }
                <div className="WebinarCallouts flex">
                    <div className="Callout-Half white-on-green">
                        <div className="Default-Callout-Content">
                            <h2>Talk to us. We're here for&nbsp;you.</h2>
                            <p>Want to chat? One of our live agents is ready to answer your&nbsp;questions.</p>
                        </div>
                    </div>
                    <div className="Callout-Half darkgrey-on-grey">
                        <div className="Default-Callout-Content">
                            <h2>Got any questions?</h2>
                            <p>Call our team of experts at 1-877-371-BASF (2273) to reach AgSolutions<sup>®</sup> Customer&nbsp;Care.</p>
                            <a href="https://twitter.com/basfagsolutions" target="_blank" rel="noopener noreferrer"><img className="socialmedia-img" src={TwitterImg} alt="Twitter" /></a>
                            <a href="http://www.youtube.com/user/BASFAgSolutions" target="_blank" rel="noopener noreferrer"><img className="socialmedia-img" src={YouTubeImg} alt="YouTube" /></a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WebinarPage;