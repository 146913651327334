import React, { Component } from 'react';

import Modal from '../Modal/Modal';
import PrivacyConsent from '../../resources/Documents/BASF_PIPEDA_Privacy_Consent.pdf';
import CEMConsent from '../../resources/Documents/BASF_CEM_Commercial_Consent.pdf';
import { baseURL } from '../../constants/api';

import './Questionaire.css';

class Questionaire extends Component {
	constructor(props) {
		super(props);

		this.state = {
			inviteeID: null,
			rtid: this.parseQuery('rtid'),
			pin_email: null,
			default_email: null,
			pin: this.parseQuery('pin'),
			contactType: '',
			user_type: '',
			cca_number: '',
			ccsc_number: '',
			crop_consultant: false,
			first_name: '',
			last_name: '',
			farm_name: '',
			address: '',
			city: '',
			province: '',
			postal_code: '',
			phone: '',
			email: '',
			heard_from: '',
			contactCode: '',

			privacy_consent: false,
			cem_consent: false,
			receive_email: false,
			receive_sms: false,
			receive_email_val: '',
			receive_sms_val: '',

			isValid: false,
			loading: false,
			showError: false,
			errorMessage: '',
		};

		this.formRef = React.createRef();
	}

	parseQuery = (param) => {
		let returnVal = null;

		if (window.location.search.length > 0) {
			(window.location.search[0] === '?'
				? window.location.search.substr(1)
				: window.location.search
			)
				.split('&')
				.forEach((pair) => {
					if (pair.split('=')[0] === param)
						returnVal =
							pair.split('=').length > 1
								? pair.split('=')[1]
								: 'true';
				});
		}

		return returnVal;
	};

	componentWillMount() {
		const userTypes = {
			1: 'Grower',
			2: 'Retail',
			3: 'Agronomist',
			4: 'Sales Rep',
			5: 'Media',
			6: 'Other',
		};

		const provinces = {
			AB: 'Alberta',
			BC: 'British Columbia',
			MB: 'Manitoba',
			NB: 'New Brunswick',
			NL: 'Newfoundland and Labrador',
			ON: 'Ontario',
			QC: 'Quebec',
			SK: 'Saskatchewan',
		};

		if (this.props.includeSurvey) {
			let answers = {};
			this.props.questions.map((q) => {
				answers[`survey_answer_${q.QuestionID}`] =
					q.QuestionType === 'textarea' || q.QuestionType === 'radio'
						? ''
						: [];
				if (q.hasOther)
					answers[`survey_answer_${q.QuestionID}_other`] = '';
				return true;
			});
			this.setState({ ...answers });
		}

		if (this.props.isLegacy) {
			if (
				RegExp('[A-Za-z0-9]{7,8}').test(this.parseQuery('pin')) &&
				RegExp('[0-9]+').test(this.parseQuery('rtid'))
			) {
				fetch(
					`${baseURL}/displayuserlegacy?pin=${this.parseQuery(
						'pin'
					)}&rtid=${this.parseQuery('rtid')}`,
					{
						method: 'GET',
						cache: 'no-cache',
						headers: {
							'Content-Type': 'application/json',
						},
						referrer: 'no-referrer',
					}
				).then((response) =>
					response.json().then((response) => {
						if (
							response.data &&
							response.data.CampaignCode ===
								this.props.campaign_code
						) {
							this.setState({
								inviteeID: response.data.inviteeID,
								user_type: userTypes[response.data.userTypeID],
								contactType:
									userTypes[response.data.userTypeID],
								cca_number: response.data.ccaNo || '',
								ccsc_number: response.data.ccscNo || '',
								first_name: response.data.firstname,
								last_name: response.data.lastname,
								farm_name: response.data.farmName,
								address: response.data.address,
								city: response.data.city,
								province: provinces[response.data.Province],
								postal_code: response.data.PostalCode,
								phone: response.data.phone,
								email: response.data.email,
								default_email: response.data.email,
								pin_email: response.data.email,

								receive_email:
									response.data.CEMConsent &&
									response.data.email !== '',
								receive_sms:
									response.data.CEMConsent &&
									response.data.phone !== '',
							});
						}
					})
				);
			}
		} else {
			if (RegExp('[A-Za-z0-9]{7,8}').test(this.parseQuery('pin'))) {
				fetch(`${baseURL}/displayuser?pin=${this.parseQuery('pin')}`, {
					method: 'GET',
					cache: 'no-cache',
					headers: {
						'Content-Type': 'application/json',
					},
					referrer: 'no-referrer',
				}).then((response) =>
					response.json().then((response) => {
						if (
							response.data &&
							response.data.OccurrenceCode ===
								this.props.campaign_code
						) {
							this.setState({
								user_type: userTypes[response.data.userTypeID],
								contactType:
									userTypes[response.data.userTypeID],
								cca_number: response.data.ccaNo || '',
								ccsc_number: response.data.ccscNo || '',
								first_name: response.data.firstname,
								last_name: response.data.lastname,
								farm_name: response.data.farmName,
								address: response.data.address,
								city: response.data.city,
								province: provinces[response.data.Province],
								postal_code: response.data.PostalCode,
								phone: response.data.phone,
								email: response.data.email,
								default_email: response.data.email,
								pin_email: response.data.email,
								contactCode: response.data.ContactCode,

								receive_email:
									response.data.CEMConsent &&
									response.data.email !== '',
								receive_sms:
									response.data.CEMConsent &&
									response.data.phone !== '',
							});
						}
					})
				);
			}
		}
	}

	renderInfoQuestion = (
		label,
		name,
		type,
		required,
		options,
		validation,
		maxlength
	) => {
		let input;

		switch (type) {
			case 'text':
				input = (
					<input
						name={name}
						type='text'
						onChange={(e) =>
							this.handleChange(e, options, validation)
						}
						value={this.state[name]}
						required={required}
						maxLength={maxlength}
					/>
				);
				break;
			case 'select':
				input = (
					<select
						name={name}
						onChange={(e) =>
							this.setState(
								{ [e.target.name]: e.target.value },
								() => this.checkValidity()
							)
						}
						value={this.state[name]}
						required={required}
					>
						<option value=''>Please select</option>
						{options.map((o, i) => (
							<option key={i} value={o}>
								{o}
							</option>
						))}
					</select>
				);
				break;
			default:
				input = null;
		}

		return (
			<div
				className={`Info-Question ${
					name === 'cca_number' || name === 'ccsc_number'
						? 'cca_number'
						: ''
				}`}
			>
				<div className='question-label'>
					{label}
					{required ? <span className='required'>*</span> : ''}
				</div>
				{input}
			</div>
		);
	};

	renderSurveyQuestion = (q, i) => {
		let input;

		function handleCheckboxChange(e, self) {
			let vals = self.state[e.target.name] || [];

			if (e.target.checked) vals.push(e.target.value);
			else vals.splice(vals.indexOf(e.target.value), 1);

			self.setState({ [e.target.name]: vals }, () =>
				self.checkValidity()
			);
		}

		if (
			q.hasOther &&
			q.Answers.length >= 6 &&
			!(q.Answers.slice(-6) === '¥Other')
		)
			q.Answers += '¥Other';

		switch (q.QuestionType) {
			case 'textarea':
				input = (
					<React.Fragment>
						<textarea
							maxLength='160'
							value={this.state[`survey_answer_${q.QuestionID}`]}
							onChange={(e) =>
								this.setState(
									{
										[`survey_answer_${q.QuestionID}`]: e
											.target.value,
									},
									() => this.checkValidity()
								)
							}
						/>
						<div>
							{this.state[`survey_answer_${q.QuestionID}`]
								? 160 -
								  this.state[`survey_answer_${q.QuestionID}`]
										.length
								: 160}{' '}
							characters remaining
						</div>
					</React.Fragment>
				);
				break;
			case 'checkbox':
			case 'radio':
				input = q.Answers.split('¥').map((a, i) => {
					let otherText = null;
					if (a === 'Other') {
						otherText = (
							<div className='Centered-Answer-Child'>
								<input
									required={
										q.QuestionType === 'radio'
											? this.state[
													`survey_answer_${q.QuestionID}`
											  ] === 'Other'
											: this.state[
													`survey_answer_${q.QuestionID}`
											  ].indexOf('Other') > -1
									}
									className='other-text'
									type='text'
									name={`survey_answer_${q.QuestionID}_other`}
									value={
										this.state[
											`survey_answer_${q.QuestionID}_other`
										]
									}
									onChange={(e) =>
										this.setState(
											{ [e.target.name]: e.target.value },
											() => this.checkValidity()
										)
									}
								/>
							</div>
						);
					}

					return (
						<div key={i} className='Centered-Answer'>
							<div className='Centered-Answer-Child'>
								<input
									type={q.QuestionType}
									value={a}
									name={`survey_answer_${q.QuestionID}`}
									onChange={
										q.QuestionType === 'checkbox'
											? (e) =>
													handleCheckboxChange(
														e,
														this
													)
											: (e) =>
													this.setState(
														{
															[e.target.name]:
																e.target.value,
														},
														() =>
															this.checkValidity()
													)
									}
								/>
							</div>
							<div className='Centered-Answer-Child'>{a}</div>
							{otherText}
						</div>
					);
				});
				break;

			default:
				input = null;
		}

		return (
			<div className='Survey-Question' key={i}>
				<h2>{q.QuestionText}</h2>
				{input}
			</div>
		);
	};

	renderConsentQuestion = (label, name, required, textbox) => {
		let input = null;

		if (textbox) {
			input = (
				<div
					className={`${
						name === 'receive_email' || name === 'receive_sms'
							? 'Consent'
							: ''
					} Centered-Answer-Child`}
				>
					<input
						className='other-text'
						required={this.state[name]}
						disabled={!this.state[name]}
						type='text'
						value={this.state[`${name}_val`]}
						name={`${name}_val`}
						onChange={(e) =>
							this.setState(
								{ [e.target.name]: e.target.value },
								() => this.checkValidity()
							)
						}
					/>
				</div>
			);
		}

		return (
			<div
				className={`Consent-Question ${
					name === 'crop_consultant' ? 'crop_consultant' : ''
				}`}
			>
				<div className='Centered-Answer'>
					<div className='Centered-Answer-Child'>
						{name === 'cem_consent' ? (
							<input
								required={required}
								type='checkbox'
								checked={this.state[name]}
								name={name}
								onChange={(e) =>
									this.setState(
										{
											[e.target.name]: e.target.checked,
											receive_email_val: this.state.email,
											receive_sms_val: this.state.phone,
										},
										() => this.checkValidity()
									)
								}
							/>
						) : (
							<input
								required={required}
								type='checkbox'
								checked={this.state[name]}
								name={name}
								onChange={(e) =>
									this.setState(
										{ [e.target.name]: e.target.checked },
										() => this.checkValidity()
									)
								}
							/>
						)}{' '}
					</div>
					<div
						className={`${
							name === 'receive_email' || name === 'receive_sms'
								? 'Consent'
								: ''
						} Centered-Answer-Child`}
						dangerouslySetInnerHTML={{
							__html: `${label}${
								required
									? `<span class="required">*</span>`
									: ''
							}`,
						}}
					/>
					{input}
				</div>
			</div>
		);
	};

	checkValidity = () => {
		let old_validity = this.state.isValid;
		let new_validity = this.formRef.current.checkValidity();

		if (old_validity !== new_validity)
			this.setState({ isValid: new_validity });
	};

	handleChange = (e, options, validation) => {
		if (options) {
			let changeValidator = RegExp(options);
			if (changeValidator.test(e.target.value))
				this.setState({ [e.target.name]: e.target.value }, () =>
					this.checkValidity()
				);
		}

		if (validation) {
			let finalValidator = RegExp(validation);
			if (finalValidator.test(e.target.value))
				e.target.setCustomValidity('');
			else e.target.setCustomValidity('Invalid format');
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		if (this.state.isValid && !this.state.loading) {
			this.setState({ loading: true }, () => {
				const userTypes = {
					Grower: '1',
					Retail: '2',
					Agronomist: '3',
					'Sales Rep': '4',
					Media: '5',
					Other: '6',
				};

				const provinces = {
					Alberta: 'AB',
					'British Columbia': 'BC',
					Manitoba: 'MB',
					'New Brunswick': 'NB',
					'Newfoundland and Labrador': 'NL',
					Ontario: 'ON',
					Quebec: 'QC',
					Saskatchewan: 'SK',
				};

				let answers = {};

				if (
					this.props.includeSurvey &&
					this.props.questions.length > 0
				) {
					let checkArray = [];
					this.props.questions.map((q) => {
						if (
							q.QuestionType === 'textarea' ||
							q.QuestionType === 'radio'
						) {
							if (
								q.hasOther &&
								this.state[`survey_answer_${q.QuestionID}`] ===
									'Other'
							) {
								answers[q.QuestionID] = this.state[
									`survey_answer_${q.QuestionID}_other`
								];
							} else {
								answers[q.QuestionID] = this.state[
									`survey_answer_${q.QuestionID}`
								];
							}
						} else if (q.QuestionType === 'checkbox') {
							checkArray = this.state[
								`survey_answer_${q.QuestionID}`
							];
							if (
								q.hasOther &&
								checkArray.indexOf('Other') !== -1
							) {
								checkArray[
									checkArray.indexOf('Other')
								] = this.state[
									`survey_answer_${q.QuestionID}_other`
								];
							}
							answers[q.QuestionID] = checkArray.join(', ');
						}
						return true;
					});
				}

				const postdata = {
					isLegacy: this.props.isLegacy,
					campaignCode: this.props.campaign_code,
					eventCode: this.props.event_code,
					attended: this.props.attended ? '1' : '0',

					pin:
						this.state.pin_email === this.state.email
							? this.state.pin
							: null,
					inviteeID:
						this.state.pin_email === this.state.email
							? this.state.inviteeID
							: null,
					registrationTrackingID: this.state.rtid,
					userTypeID: userTypes[this.state.user_type],
					contactType: this.state.contactType,
					ccaNo: this.state.cca_number,
					ccscNo: this.state.ccsc_number,
					cropConsultant: this.state.crop_consultant,
					firstName: this.state.first_name,
					lastName: this.state.last_name,
					farmName: this.state.farm_name,
					address: this.state.address,
					city: this.state.city,
					provinceCode: provinces[this.state.province],
					postalCode: this.state.postal_code,
					phone: this.state.phone,
					email: this.state.email,
					heardFrom: this.state.heard_from,
					CEMConsent: this.state.cem_consent ? '1' : null,
					CEMPhone:
						this.state.cem_consent && this.state.receive_sms
							? this.state.receive_sms_val
							: '',
					CEMEmail:
						this.state.cem_consent && this.state.receive_email
							? this.state.receive_email_val
							: '',
					contactCode:
						this.state.pin_email === this.state.email
							? this.state.contactCode
							: '',
					WebinarLink: this.props.WebinarLink,

					...answers,
					timezone: Intl.DateTimeFormat().resolvedOptions().timeZone? Intl.DateTimeFormat().resolvedOptions().timeZone: 'America/New_York',

				};

				fetch(`${baseURL}/savereg`, {
					method: 'POST',
					cache: 'no-cache',
					headers: {
						'Content-Type': 'application/json',
					},
					referrer: 'no-referrer',
					body: JSON.stringify(postdata),
				}).then((response) =>
					response.json().then((response) => {
						if (response.status === 'success') {
							this.setState({ loading: false }, () => {
								localStorage.setItem(
									this.props.campaign_code,
									response.erid
								);
								this.props.handleSubmit(response.erid);
							});
						} else if (response.status === 'error') {
							this.setState({
								errorMessage: response.message,
								showError: true,
								loading: false,
							});
						}
					})
				);
				// fetch(`${baseURL}/thirdparty`, {
				// 	method: 'POST',
				// 	cache: 'no-cache',
				// 	headers: {
				// 		'Content-Type': 'application/json',
				// 	},
				// 	referrer: 'no-referrer',
				// 	body: JSON.stringify(postdata),
				// }).then((response) =>
				// 	response.json().then((response) => {
				// 		console.log('RESPONSE!!!', response);
				// 		console.log('POSTDATA!!! - ', postdata);
				// if (response.status >= 200 && response.status <= 299) {
				// 	fetch(`${baseURL}/savereg`, {
				// 		method: 'POST',
				// 		cache: 'no-cache',
				// 		headers: {
				// 			'Content-Type': 'application/json',
				// 		},
				// 		referrer: 'no-referrer',
				// 		body: JSON.stringify(postdata),
				// 	}).then((response) =>
				// 		response.json().then((response) => {
				// 			if (response.status === 'success') {
				// 				this.setState(
				// 					{ loading: false },
				// 					() => {
				// 						localStorage.setItem(
				// 							this.props.campaign_code,
				// 							response.erid
				// 						);
				// 						this.props.handleSubmit(
				// 							response.erid
				// 						);
				// 					}
				// 				);
				// 			} else if (response.status === 'error') {
				// 				this.setState({
				// 					errorMessage: response.message,
				// 					showError: true,
				// 					loading: false,
				// 				});
				// 			}
				// 		})
				// 	);
				// }
				// })
				// );
			});
		}
	};

	render() {
		const required = <span className='required'>*</span>;

		return (
			<div className='Questionaire'>
				{this.state.showError ? (
					<Modal
						title='Registration Error'
						message={this.state.errorMessage}
						handleClose={() => this.setState({ showError: false })}
					/>
				) : null}
				<form onSubmit={this.handleSubmit} ref={this.formRef}>
					<div className='Info-Question-Container'>
						<div className='required-label'>
							{required} Required Fields
						</div>

						{this.renderInfoQuestion(
							'I am a',
							'user_type',
							'select',
							true,
							[
								'Grower',
								'Retail',
								'Agronomist',
								'Sales Rep',
								'Media',
								'Other',
							],
							false,
							false
						)}
						{this.renderInfoQuestion(
							'CCA Number',
							'cca_number',
							'text',
							false,
							'^[a-zA-Z0-9]*$',
							false,
							20
						)}
						{this.renderInfoQuestion(
							'CCSC Number',
							'ccsc_number',
							'text',
							false,
							'^[a-zA-Z0-9]*$',
							false,
							20
						)}
						{/*this.renderConsentQuestion("I am a Certified Crop Science Consultant", "crop_consultant", false, false)*/}
						{this.renderInfoQuestion(
							'First Name',
							'first_name',
							'text',
							true,
							"^[A-Za-z '-]*$",
							"^[A-Za-z '-]+$",
							50
						)}
						{this.renderInfoQuestion(
							'Last Name',
							'last_name',
							'text',
							true,
							"^[A-Za-z '-]*$",
							"^[A-Za-z '-]+$",
							50
						)}
						{this.renderInfoQuestion(
							this.state.user_type === 'Grower'
								? 'Farm Name'
								: this.state.user_type === 'Retail'
								? 'Retail Name'
								: 'Organization Name',
							'farm_name',
							'text',
							this.state.user_type === 'Retail',
							"^[A-Za-z0-9 ',.-]*$",
							"^[A-Za-z0-9 ',.-]+$",
							75
						)}
						{this.renderInfoQuestion(
							'Address',
							'address',
							'text',
							true,
							"^[A-Za-z0-9 ',.-]*$",
							"^[A-Za-z0-9 ',.-]+$",
							65
						)}
						{this.renderInfoQuestion(
							'City',
							'city',
							'text',
							true,
							"^[A-Za-z .'-]*$",
							"^[A-Za-z .'-]+$",
							35
						)}
						{this.renderInfoQuestion(
							'Province',
							'province',
							'select',
							true,
							[
								'Alberta',
								'British Columbia',
								'Manitoba',
								'New Brunswick',
								'Newfoundland and Labrador',
								'Ontario',
								'Quebec',
								'Saskatchewan',
							],
							false,
							false
						)}
						{this.renderInfoQuestion(
							'Postal Code',
							'postal_code',
							'text',
							true,
							'^[A-Za-z0-9 ]*$',
							'^[a-zA-Z][0-9][a-zA-Z] ?[0-9][a-zA-Z][0-9]$',
							7
						)}
						{this.renderInfoQuestion(
							'Phone',
							'phone',
							'text',
							true,
							'^[0-9 -]*$',
							'^(1[ -]?)?[0-9]{3}[ -]?[0-9]{3}[ -]?[0-9]{4}$',
							15
						)}
						{this.renderInfoQuestion(
							'Email',
							'email',
							'text',
							true,
							'^[A-Za-z0-9_.@-]*$',
							'[A-Za-z0-9_.-]+@[A-Za-z0-9_.-]+[.][a-zA-Z]+',
							50
						)}
						{this.renderInfoQuestion(
							'How did you hear about this event?',
							'heard_from',
							'select',
							true,
							[
								'Email',
								'Tradeshow/Event',
								'Social media',
								'BASF eNewsletter',
								'Online ad',
								'Print ad',
								'Business Rep',
								'Friend',
								'Other',
							],
							false,
							false
						)}
					</div>
					{this.props.includeSurvey ? (
						<React.Fragment>
							<hr />
							<div className='Survey-Question-Container'>
								{this.props.questions.map((q, i) => {
									return this.renderSurveyQuestion(q, i);
								})}{' '}
							</div>
						</React.Fragment>
					) : null}{' '}
					<hr />
					<div className='Consent-Question-Container'>
						{this.state.user_type === 'Grower'
							? this.renderConsentQuestion(
									`Yes, I have read and agree to provide <a href=${PrivacyConsent} target="_blank" rel="noopener noreferrer">privacy consent</a>.`,
									'privacy_consent',
									true,
									false
							  )
							: null}
						{this.renderConsentQuestion(
							`Yes, I would like to receive commercial electronic messages from BASF Canada, including email and text messages, 
							for the purposes of: (i) verifying BASF’s marketing program payments to me; (ii) developing and administering BASF’s marketing programs; 
							and (iii) developing, promoting and offering additional information, products and services to me. 
							I have read and agree to provide <a href=${CEMConsent} target="_blank" rel="noopener noreferrer">Commercial Electronic Message consent</a>.`,
							'cem_consent',
							false,
							false
						)}
						{this.state.cem_consent ? (
							<div className='CEM-Consent-Box'>
								{this.renderConsentQuestion(
									'Yes, I wish to receive email messages from BASF.',
									'receive_email',
									!this.state.receive_email &&
										!this.state.receive_sms,
									true
								)}
								{this.renderConsentQuestion(
									'Yes, I wish to receive SMS text messages from BASF.',
									'receive_sms',
									!this.state.receive_email &&
										!this.state.receive_sms,
									true
								)}
								<p>Your consent is sought by Kenna Communications LP for and on behalf of BASF Canada Inc. o/a BASF Canada. 
									You may later withdraw your consent to receive these materials without affecting your eligibility in the contest. 
									You may contact BASF Canada at: Suite 510, 28 Quarry Park Blvd. SE, Calgary, AB, T2C 5P9, Telephone: 1-866-485-BASF (2273), 
									or email: privacy.officer@basf.com.
								</p>
							</div>
						) : null}{' '}
					</div>
					<button
						className={`submit-button ${
							!this.state.loading && this.state.isValid
								? 'valid'
								: ''
						} ${this.state.loading ? 'loading' : ''}`}
						type='submit'
					>
						Submit
					</button>
				</form>
			</div>
		);
	}
}

export default Questionaire;
