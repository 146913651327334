import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import './TopNavItem.css';

const TopNavItem = (props) => 
    <NavLink exact className="TopNavItemLink" to={{ pathname: props.link, search: props.search}} activeClassName="active">
        <div className="TopNavItem" onClick={() => props.setActivePage(props.index)}>{props.name}</div>
    </NavLink>

export default withRouter(TopNavItem);