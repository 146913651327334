import React, { Component } from 'react';
//import ReactGA from 'react-ga';

import YouTubeImg from '../../../images/callouts/youtube.png';
import TwitterImg from '../../../images/callouts/twitter.png';
import QAHero from '../../../images/qa/qahero.jpg';

import QACallout from '../../Callout/QACallout/QACallout';

import './QAPage.css';
import '../../Callout/Callout.css';

class QAPage extends Component {

    constructor(props) {
        super(props);

        this.heroImages = require.context('../../../resources/Images/Hero');
        this.qaData = require.context('../../../resources/QA');
    }

    componentDidMount() {

        document.title = "BASF | Question & Answers - Webinar";
       // ReactGA.pageview("BASF | Question & Answers - Webinar");
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className={`QAPage ${this.props.stickyTopNav ? "stickyPageMargin" : ""}`}>
                <img className="HeroImage" src={QAHero} alt="Q&A" />
                {(this.props.pages.filter((page) => ((page.CONFIG_Status==="Past" || page.CONFIG_Status==="Active") && page.CONFIG_QA))).sort((b, a) => a.CONFIG_EpochStartTime - b.CONFIG_EpochStartTime).map((page,i) => <QACallout key={i} campaigncode={page.CONFIG_CampaignCode} name={page.CONFIG_Name} image={this.heroImages(`./${page.CONFIG_EventCode}.jpg`)} details={page.COPY_QADetails} qa={this.qaData(`./${page.CONFIG_EventCode}.json`)} />)}
                <div className="Callout">
                    <div className="Callout-Content white-on-green">
                        <h2>Talk to us. We're here for&nbsp;you.</h2>
                        <p>Want to chat? One of our live agents is ready to answer your&nbsp;questions.</p>
                    </div>
                    <div className="Callout-Content darkgrey-on-grey">
                        <h2>Got any questions?</h2>
                        <p>Call our team of experts at 1-877-371-BASF (2273) to reach AgSolutions<sup>®</sup> Customer&nbsp;Care.</p>
                        <a data-tealium-category="link" data-tealium-action="click footer-link" data-tealium-value="twitter||https://twitter.com/basfagsolutions" data-tealium-type="" href="https://twitter.com/basfagsolutions" target="_blank" rel="noopener noreferrer"><img className="socialmedia-img" src={TwitterImg} alt="Twitter" /></a>
                        <a data-tealium-category="link" data-tealium-action="click footer-link" data-tealium-value="youtube||http://www.youtube.com/user/BASFAgSolutions" data-tealium-type="" href="http://www.youtube.com/user/BASFAgSolutions" target="_blank" rel="noopener noreferrer"><img className="socialmedia-img" src={YouTubeImg} alt="YouTube" /></a>
                    </div>
                    <div className="Callout-Content darkgrey-on-grey">
                    </div>    
                </div>
            </div>
        );
    }
}

export default QAPage;