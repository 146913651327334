import React from 'react';

import './Footer.css';

const Footer = () => 
    <div className="Footer">
        <h4>Always read and follow label directions.</h4>
        <p>© 2023 BASF Canada Inc.</p>
        <div>
            <a target="_blank" data-tealium-category="link" data-tealium-action="click footer-link" data-tealium-value="contact||https://agriculture.basf.ca/west/contact-us.html" data-tealium-type="navigation"  rel="noopener noreferrer" href="https://agriculture.basf.ca/west/contact-us.html">Contact</a> &nbsp; | &nbsp;
            <a target="_blank" data-tealium-category="link" data-tealium-action="click footer-link" data-tealium-value="disclaimer||https://www.basf.com/ca/en/legal/disclaimer.html" data-tealium-type="navigation" rel="noopener noreferrer" href="https://www.basf.com/ca/en/legal/disclaimer.html">Disclaimer</a> &nbsp; | &nbsp;
            <a target="_blank"  data-tealium-category="link" data-tealium-action="click footer-link" data-tealium-value="credits||https://www.basf.com/ca/en/legal/credits.html" data-tealium-type="navigation" rel="noopener noreferrer" href="https://www.basf.com/ca/en/legal/credits.html">Credits</a> &nbsp; | &nbsp;
            <a target="_blank" data-tealium-category="link" data-tealium-action="click footer-link" data-tealium-value="privacy policy||https://www.basf.com/ca/en/legal/data-protection.html" data-tealium-type="navigation" rel="noopener noreferrer" href="https://www.basf.com/ca/en/legal/data-protection.html">Privacy policy</a>
        </div>
    </div>

export default Footer;