const currentWebinar = {
	CONFIG_EventCode: '67C8A76E-741B-4FCD-B803-123063FB4E6E',
	CONFIG_Name: 'Beyond the Bushel Webinar ',
};

const pages = [
	{
		CONFIG_Legacy: false,
		CONFIG_QA: false,
		CONFIG_CampaignCode: '25535A61-DA4F-40DF-B99A-2ADD1CA50A03', // this is actually occurrenceCode??
		CONFIG_EventCode: 'C1B5456D-937B-4500-937B-8F917F371A59',
		CONFIG_Name: '2023 Take Stalk Best Management Solutions for Corn Webinar',
		CONFIG_Status: 'Past',
		CONFIG_EpochStartTime: 1677592800,
		CONFIG_TopNavName: '2023 Take Stalk Best Management Solutions for Corn Webinar',
		CONFIG_Link: '/tsvw',
		CONFIG_EarnCredit: true,
		CONFIG_BottomCallout: false,
		COPY_Details: '<p>Every corn input you choose is an investment in the yield and quality you expect at the end of the season. Our 2023 <strong>Take Stalk Best Management Solutions for Corn Webinar</strong> brings together an interactive, expert panel to cover practical advice for getting the best from your corn crop. We’ll cover key diseases, best practices to maximize yield, and introduce Veltyma<sup>®</sup> DLX, our newest corn fungicide solution that’s powered by Revysol<sup>®</sup>. Register now and submit questions to the panel for the Q&A session at the end of the webinar.</p><p><b>Date</b>: Tuesday, February 28, 2023<br/><b>Time</b>: 9:00 – 10:30 am (EST)<br/>Sponsored by BASF Canada<br/>Hosted by Shaun Haney – Founder of RealAgriculture</p><p>Hear from our expert corn panel:</p><ul><li><b>Ryan Benjamins</b> - Independent Agronomist, Lambton County</li><li><b>Patrick Butters</b> - Butters Farms, Elgin County</li><li><b>Ken Currah</b> - BASF AgSolutions Retail Representative, Eastern Canada</li></ul>',
		COPY_MainHeader:
			'Register now: 2023 Take Stalk Best Management Solutions for Corn Webinar.',
		COPY_MainCalloutBefore:
			'The Take Stalk Corn Webinar airs on Tuesday, February 28, 2023 at 9:00 am (EST). The webinar features an expert corn panel that will cover best management solutions for managing disease and maximizing yield for your 2023 corn crop.',
		COPY_MainCalloutDuring:
			'The Take Stalk Corn Webinar airs on Tuesday, February 28, 2023 at 9:00 am (EST). Join the Take Stalk Webinar to learn best management solutions for the 2023 corn crop. The webinar features an expert corn panel that will cover key agronomic recommendations for your corn crop this year. You’ll be eligible to earn one CEU credit for attending if you are a CCA and/or CCSC.',
		COPY_MainCalloutAfter:
			'The Take Stalk Webinar has ended, but we’ve got it on record. You’ll be able to go back and listen to the great agronomic information provided to help you manage your corn crop this year. And if you are a CCA and/or CCSC, you’re still eligible for one CEU credit for watching the recorded webinar.',
		COPY_MainCalloutPast:
			'Watch the Take Stalk Webinar to learn how to manage challenges in your corn crop this year. You’ll hear about key best management solutions from our expert panel. And you are eligible to earn one CEU credit for watching this recorded webinar, if you are a CCA and/or CCSC.',
		COPY_BottomCalloutContent:
			'<h1>Seed To Succeed</h1><p>The Seed to Succeed Webinar tackles InVigor<sup>®</sup> hybrid canola research, new approaches and seeding practices to optimize your canola seeding approach. </p>',
		COPY_ShareEmailSubject:
			'BASF%3A%20Take%20Stalk%20Best%20Management%20Solutions%20for%20Corn%20Webinar',
		COPY_ShareEmailBody:
			'Someone%20you%20know%20just%20registered%20for%20the%202023%20Take%20Stalk%20Webinar%20and%20they%20thought%20you%20might%20be%20interested%20too.%20The%20webinar%20is%20Tuesday%2C%20February%2028%2C%202023%20at%209%3A00%20am%20%28EST%29%20and%20our%20panel%20of%20corn%20experts%20will%20cover%20best%20management%20solutions%20for%202023.%20Visit%20https%3A%2F%2Fagsolutions.ca%2Fwebinar%20to%20register.%20The%20event%20qualifies%20for%20one%20CCA%20and%2For%20CCSC%20continuing%20education%20credit.%0A%0AHope%20to%20see%20you%20there.',
		COPY_ShareTwitter:
			'I%20just%20registered%20for%20this%20year%E2%80%99s%20%23TakeStalk%20webinar%20to%20hear%20from%20an%20expert%20panel%20on%20best%20management%20solutions%20for%20corn.%20Sign%20up%20for%20the%20Feb%2028%2C%202023%20event%20here.%20https%3A%2F%2Fbit.ly%2F3Jkc9Nu%20%23Revylution%20%23EastCdnAg',
		COPY_QADetails:
			'<h3>Host: Shaun Haney</h3><div><b>Presenters:</b></div><div><b>Albert Tenuta</b> | OMAFRA Pathologist – Field Crops</div><div><b>Rob Miller</b> | BASF Regional Technical Services Manager, Eastern Canada | BASF</div><div><b>Mike Miller</b> | Miller Family Farms</div>',
		STYLE_BottomCalloutTextColor: '#000000',
		STYLE_BottomCalloutBackgroundColor: '#ede9ea',
		RES_WebinarLink: 'https://kenna.adobeconnect.com/invigorresults',
		RES_Presentation: true,
		RES_VideoLink: 'MRt1tBGXktg',
		RES_BottomCalloutLink: null,
	},
	{
		CONFIG_Legacy: false,
		CONFIG_QA: true,
		CONFIG_CampaignCode: 'D0B65E0E-88DA-4542-A999-0FE960A2C54A', // this is actually occurrenceCode??
		CONFIG_EventCode: '67C8A76E-741B-4FCD-B803-123063FB4E6E',
		CONFIG_Name: '2023 Beyond the Bushel Webinar',
		CONFIG_Status: 'Past',
		CONFIG_EpochStartTime: 1675267200,
		CONFIG_TopNavName: '2023 Beyond the Bushel Webinar',
		CONFIG_Link: '/btb',
		CONFIG_EarnCredit: true,
		CONFIG_BottomCallout: false,
		COPY_Details: '<p>InVigor<sup>®</sup> hybrid canola innovation is advancing. Are you keeping up? At the Beyond the Bushel Webinar, our panel will be leading an interactive discussion on all the ways you can get the most out of InVigor genetics. Register now to submit specific questions you’d like to see addressed by the panel members.</p><p><b>Date</b>: Wednesday, February 1, 2023<br/><b>Time</b>: Alberta: 9:00 AM | Manitoba and Saskatchewan: 10:00 AM<br/><b>Who</b>: Hosted by Shaun Haney – Founder of RealAgriculture</p><p>You’ll also hear from:</p><ul><li><b>Rob MacDonald</b> - Manager Agronomic Excellence & Product Advancement</li><li><b>Allison Bishop</b> - Technical Services Specialist</li><li><b>Traci Blenkin</b> - Technical Services Specialist</li><li><b>Brent Galbraith</b> - Brand Manager - Canola Herbicides</li></ul>',
		COPY_MainHeader:
			'Beyond the Bushel Webinar. Register now.',
		COPY_MainCalloutBefore:
			'The Beyond the Bushel Webinar airs on February 1, 2023, at 9:00 AM MST (Alberta) or 10 AM CST (Manitoba and Saskatchewan). Register now to hear insights from our BASF panel who will be discussing ways to get the most out of your hybrid canola.',
		COPY_MainCalloutDuring:
			'Register now to join the Beyond the Bushel Webinar to keep up with the latest InVigor<sup>®</sup> hybrid canola advancements. The webinar features an interactive panel discussion and will earn you one CEU credit for joining us.',
		COPY_MainCalloutAfter:
			'We recorded our Beyond the Bushel Webinar, so you don’t have to miss what our team had to say about optimizing your InVigor<sup>®</sup> hybrid canola practices. Good news, you’re also still eligible to earn one CEU credit for watching the full recording now.',
		COPY_MainCalloutPast:
			'Watch a recording of the Beyond the Bushel Webinar to gain valuable information on the practices you can implement on your farm to optimize your InVigor<sup>®</sup> hybrid canola performance. You’ll also earn one CEU credit just for attending.',
		COPY_BottomCalloutContent:
			'<h1>Seed To Succeed</h1><p>The Seed to Succeed Webinar tackles InVigor<sup>®</sup> hybrid canola research, new approaches and seeding practices to optimize your canola seeding approach. </p>',
		COPY_ShareEmailSubject:
			'BASF%3A%20Your%20invite%20to%20the%20Beyond%20the%20Bushel%20Webinar',
		COPY_ShareEmailBody:
			'I%20just%20registered%20for%20the%20Beyond%20the%20Bushel%20Webinar%20and%20I%20thought%20you%20might%20want%20to%20join.%20The%20webinar%20will%20take%20place%20on%20February%201%2C%202023%2C%20at%209%3A00%20AM%20%28Alberta%29%20or%2010%20AM%20%28Manitoba%20and%20Saskatchewan%29.%20Please%20visit%20agsolutions.ca%2Fwebinar%20to%20register%2C%20so%20you%20can%20join%20the%20interactive%20discussion%20on%20maximizing%20InVigor%C2%AE%20hybrid%20canola%20acres.%20You%E2%80%99ll%20also%20earn%20one%20CEU%20credit%20just%20for%20attending.%20%0A%0AI%20hope%20to%20see%20you%20there.%0A',
		COPY_ShareTwitter:
			'Have%20any%20plans%20for%20February%201%3F%20Now%20you%20do.%20Register%20now%20for%20the%20Beyond%20the%20Bushel%20Webinar%3A%20https%3A%2F%2Fbit.ly%2F3InAcun%20%23InVigor%20%23canola%20%23WestCdnAg%0A',
		COPY_QADetails:
			'<h3>Host: Shaun Haney</h3><div><b>Presenters:</b></div><div><b>Rob MacDonald</b> | Manager Agronomic Excellence & Product Advancement</div><div><b>Allison Bishop</b> | Technical Services Specialist</div><div><b>Traci Blenkin</b> | Technical Services Specialist</div><div><b>Brent Galbraith</b> | Brand Manager - Canola Herbicides</div>',
		STYLE_BottomCalloutTextColor: '#000000',
		STYLE_BottomCalloutBackgroundColor: '#ede9ea',
		RES_WebinarLink: 'https://kenna.adobeconnect.com/invigorresults',
		RES_Presentation: true,
		RES_VideoLink: 'mlWRqcbiOjM',
		RES_BottomCalloutLink: null,
	},
	{
		CONFIG_Legacy: false,
		CONFIG_QA: false,
		CONFIG_CampaignCode: '215F8A79-2AB4-47B8-A72F-E7BFEB9AC4B8', // this is actually occurrenceCode??
		CONFIG_EventCode: '7E89F5E3-D44F-45BC-9362-C43107F68977',
		CONFIG_Name: '2022 Take Stalk Best Management Solutions for Corn Webinar',
		CONFIG_Status: 'Past',
		CONFIG_EpochStartTime: 1645624800,
		CONFIG_TopNavName: '2022 Take Stalk Best Management Solutions for Corn Webinar',
		CONFIG_Link: '/tsbms',
		CONFIG_EarnCredit: true,
		CONFIG_BottomCallout: false,
		COPY_Details: '<p>You’ve got a lot of decisions to make for your 2022 corn crop. Join the <strong>Take Stalk Best Management Solutions for Corn Webinar</strong>, we’ll cover key agronomic recommendations – from hybrid selection, tillage, crop rotation to disease identification and management. An expert panel will talk about best management solutions for corn production. We’ll introduce Veltyma<sup>®</sup>, powered by Revysol<sup>®</sup>, our newest fungicide solution for 2022. Register now and submit questions to the panel for the Q&A session at the end of the webinar.</p><p><b>Date</b>: Wednesday, February 23, 2022<br/><b>Time</b>: 9:00 - 10:30 AM (EST)<br/>Sponsored by BASF Canada<br/>Hosted by Shaun Haney, Real Agriculture </p><p>Hear from our expert corn panel:</p><ul><li><b>Albert Tenuta</b> - OMAFRA Pathologist - Field Crops</li><li><b>Rob Miller</b> - BASF Regional Technical Services Manager, Eastern Canada</li><li><b>Mike Miller</b> - Miller Family Farms</li></ul>',
		COPY_MainHeader:
			'Take Stalk Best Management Solutions for Corn Webinar. Register now.',
		COPY_MainCalloutBefore:
			'The Take Stalk Webinar airs on Wednesday, February 23, 2022 at 9:00 am (EST). The webinar features an expert corn panel that will cover best management solutions for tackling weed and disease pressure for your 2022 corn crop.',
		COPY_MainCalloutDuring:
			'Join the Take Stalk Webinar to learn best management solutions for the 2022 corn crop. The webinar features an expert corn panel that will cover key agronomic recommendations to help you tackle weed and disease pressure this year. You’ll be eligible to earn one CEU credit for attending if you are a CCA and/or CCSC',
		COPY_MainCalloutAfter:
			'The Take Stalk Webinar has ended, but we’ve got it on record. You’ll be able to go back and listen to the great agronomic information designed to help you tackle weed and disease pressure in your corn crop this year. And if you are a CCA and/or CCSC, you’re still eligible for one CEU credit for watching the recorded webinar.',
		COPY_MainCalloutPast:
			'Watching a recording of the Take Stalk Webinar to learn how to manage challenges in your corn crop this year. You’ll hear about key best management solutions from our expert panel. And you are eligible to earn one CEU credit for watching this recorded webinar, if you are a CCA and/or CCSC.',
		COPY_BottomCalloutContent:
			'<h1>Seed To Succeed</h1><p>The Seed to Succeed Webinar tackles InVigor<sup>®</sup> hybrid canola research, new approaches and seeding practices to optimize your canola seeding approach. </p>',
		COPY_ShareEmailSubject:
			'BASF%3A%20Take%20Stalk%20Best%20Management%20Solutions%20for%20Corn%20Webinar',
		COPY_ShareEmailBody:
			'Someone%20you%20know%20just%20registered%20for%20the%20Take%20Stalk%20Webinar%20and%20they%20thought%20you%20might%20be%20interested%20in%20it.%20The%20webinar%20is%20on%20Wednesday%2C%20February%2023%2C%202022%20at%209%3A00%20am%20(EST)%20and%20our%20panel%20of%20corn%20experts%20will%20cover%20best%20management%20solutions%20for%202022.%20Visit%20agsolutions.ca%2Fwebinar%20to%20register.%20If%20you%20are%20a%20CCA%20and%2For%20CCSC%2C%20you%20can%20earn%20one%20CEU%20credit%20for%20attending.%0A%0AHope%20to%20see%20you%20there.',
		COPY_ShareTwitter:
			'I%20just%20registered%20for%20the%20%23TakeStalk%20webinar%20to%20hear%20from%20an%20expert%20panel%20on%20best%20management%20solutions%20for%20corn.%20Sign%20up%20for%20the%20Feb%2023%2C%202022%20event%20here:%20https://bit.ly/2PoTWEI%20%23BASFWebinars2022%20%23EastCdnAg',
		COPY_QADetails:
			'<h3>Host: Shaun Haney</h3><div><b>Presenters:</b></div><div><b>Albert Tenuta</b> | OMAFRA Pathologist – Field Crops</div><div><b>Rob Miller</b> | BASF Regional Technical Services Manager, Eastern Canada | BASF</div><div><b>Mike Miller</b> | Miller Family Farms</div>',
		STYLE_BottomCalloutTextColor: '#000000',
		STYLE_BottomCalloutBackgroundColor: '#ede9ea',
		RES_WebinarLink: 'https://kenna.adobeconnect.com/invigorresults',
		RES_Presentation: false,
		RES_VideoLink: '5kmCxrKOabs',
		RES_BottomCalloutLink: null,
	},
	{
		CONFIG_Legacy: false,
		CONFIG_QA: true,
		CONFIG_CampaignCode: 'E966DE88-74F1-4DB3-A6CC-DBA13FB13E73', // this is actually occurrenceCode??
		CONFIG_EventCode: '4D528018-8C8A-4D94-BA95-8C2C92DE0CB6',
		CONFIG_Name: '2022 Beyond the Bushel Webinar',
		CONFIG_Status: 'Past',
		CONFIG_EpochStartTime: 1638806400,
		CONFIG_TopNavName: '2022 Beyond the Bushel Webinar',
		CONFIG_Link: '/bbw',
		CONFIG_EarnCredit: true,
		CONFIG_BottomCallout: false,
		COPY_Details:
			'<p>A season’s success can be measured in a number of different ways. At the Beyond the Bushel Webinar, we go beyond the yield numbers to discuss how grain prices, market outlook, sustainability and more could influence how you measure success on your farm. Register now for your chance to submit specific questions you’d like to see addressed by the industry experts and a grower panelist during the Q&A session at the end of the webinar.</p><p><b>Date</b>: December 6, 2021</p><p><b>Time</b>: Alberta: 9:00 AM | Manitoba and Saskatchewan: 10:00 AM </p><p><b>Who</b>: Hosted by Shaun Haney – Founder of Real Agriculture </p><br/><p>You’ll also hear from:</p><p><b>Wes Petkau</b> | Senior Analyst, Oilseed and Veg Oils | IHS Markit</p><p><b>Peter Rohde</b> | Vice President, Head of Client Advisory & Development | IHS Markit</p><p><b>Garth Hodges</b> | Vice President, North America Business Management Seeds | BASF</p><p><b>Janel Delage</b> | Delage Farms</p>',
		COPY_MainHeader:
			'Beyond the Bushel Webinar – Register now.',
		COPY_MainCalloutBefore:
			'The Beyond the Bushel Webinar airs on December 6<sup>th</sup>, 2021, at 9:00 AM MST (Alberta) or 10 AM CST (Manitoba and Saskatchewan). The webinar will feature insights from a team of experts and a grower panelist on the canola market, grain prices, sustainable practices and more.',
		COPY_MainCalloutDuring:
			'Join the Beyond the Bushel Webinar to gain the latest insights on the canola market. Hear from a group of experts who offer their specialized insights on the canola industry. The webinar will not only add to your knowledge, but you will also be eligible to earn one CEU credit just for attending.',
		COPY_MainCalloutAfter:
			'We recorded our Beyond the Bushel Webinar, so you don’t have to miss what the industry experts had to say about the canola market and its outlook. Don’t worry, you’re also still eligible to earn one CEU credit for attending.',
		COPY_MainCalloutPast:
			'Watch a recording of the Beyond the Bushel Webinar to gain valuable information on the canola industry and its market outlook. Hear from the experts on grain prices, sustainability and more. You’ll also earn one CEU credit just for attending.',
		COPY_BottomCalloutContent:
			'<h1>Seed To Succeed</h1><p>The Seed to Succeed Webinar tackles InVigor<sup>®</sup> hybrid canola research, new approaches and seeding practices to optimize your canola seeding approach. </p>',
		COPY_ShareEmailSubject:
			'BASF%20%7C%20Register%20for%20the%20Beyond%20the%20Bushel%20%20Webinar.',
		COPY_ShareEmailBody:
			'Hi%20There%2C%0A%0ASomeone%20you%20know%20just%20registered%20for%20the%20Beyond%20the%20Bushel%20Webinar%20and%20they%20thought%20you%20might%20want%20to%20join%20them.%20The%20webinar%20will%20take%20place%20on%20December%206,%202021,%20at%209:00%20AM%20(Alberta)%20or%2010%20AM%20(Manitoba%20and%20Saskatchewan).%20Please%20visit%20agsolutions.ca/webinar%20to%20register,%20so%20you%20can%20join%20the%20conversation%20on%20the%20canola%20market%20outlook.%20You’ll%20also%20earn%20one%20CEU%20credit%20just%20for%20attending.%0A%0AWe%20hope%20to%20see%20you%20there.',
		COPY_ShareTwitter:
			'I%20just%20signed%20up%20for%20the%20#BeyondTheBushel%20Webinar%20where%20I’ll%20be%20hearing%20a%20panel%20of%20experts%20discuss%20the%20ever-evolving%20canola%20market%20and%20more%20beyond%20yield%20numbers.%20You%20can%20join%20me%20on%20December%206%20when%20you%20register%20here:%20https://bit.ly/2PoTWEI%20%23BASFWebinars2021%20%23WestCdnAg',
		COPY_QADetails:
			'<h3>Host: Shaun Haney</h3><div><b>Presenters:</b></div><div><b>Wes Petkau</b> | Senior Analyst, Oilseed and Veg Oil | IHS Markit</div><div><b>Peter Rohde</b> | Vice President, Head of Client Advisory & Development | IHS Markit</div><div><b>Garth Hodges</b> | Vice President, North America Business Management Seeds | BASF</div><div><b>Janel Delage</b> | Delage Farms</div>',
		STYLE_BottomCalloutTextColor: '#000000',
		STYLE_BottomCalloutBackgroundColor: '#ede9ea',
		RES_WebinarLink: 'https://kenna.adobeconnect.com/invigorresults',
		RES_Presentation: true,
		RES_VideoLink: 'I0a_gp78MjU',
		RES_BottomCalloutLink: null,
	},
	{
		CONFIG_Legacy: false,
		CONFIG_QA: true,
		CONFIG_CampaignCode: 'DF6A0D8B-6550-48D0-91C0-71F2ACE21039', // this is actually occurrenceCode??
		CONFIG_EventCode: '82BD957B-90BC-478F-B76D-E0C802345D3E',
		CONFIG_Name: 'Myths + Facts on Fungicide Resistance Webinar',
		CONFIG_Status: 'Past',
		CONFIG_EpochStartTime: 1616598000,
		CONFIG_TopNavName: 'Myths + Facts on Fungicide Resistance Webinar',
		CONFIG_Link: '/frm',
		CONFIG_EarnCredit: true,
		CONFIG_BottomCallout: false,
		COPY_Details:
			'<p>There have been many stories told about fungicide resistance—some fact and some fiction. Our panel of experts will set the record straight during the Myths + Facts on Fungicide Resistance Webinar. That way you can get a solid understanding of the differences between herbicide and fungicide resistance, what we have learned from other situations and best management practices to keep resistance at bay. When you register, you’ll also have the opportunity to submit questions beforehand that you’d like the panel to answer.</p><p>Tune in for the one-hour event on <b>Wednesday, March 24th, 2021 at 9 AM MST</b>.</p>\r\n <p>Hosted by <b>Shaun Haney</b> – Founder of Real Agriculture</p>\r\n<p>Hear from industry experts:</p><p><b>Colleen Redlick</b> | Technical Marketing Specialist – Fungicides | BASF Canada</p><p><b>Kelly Turkington</b> | Plant Pathologist | Agriculture and Agri-Food Canada – Lacombe</p><p><b>Duane Rathmann</b> | Technical Service Representative – South Minnesota | BASF USA</p>',
		COPY_MainHeader:
			'Myths + Facts on Fungicide Resistance Webinar – Register now.',
		COPY_MainCalloutBefore:
			'The Myths + Facts on Fungicide Resistance Webinar airs on Wednesday, March 24th, 2021 at 9 AM MST. The webinar will feature a panel of experts who will discuss the challenge of fungicide resistance, as well as ways to manage it.',
		COPY_MainCalloutDuring:
			'Join the Myths + Facts on Fungicide Resistance Webinar to hear about the challenge of fungicide resistance and the best ways to manage it.',
		COPY_MainCalloutAfter:
			'The Myths + Facts on Fungicide Resistance Webinar recently ended. No need to worry if you missed it. We’ve recorded the webinar, so you won’t have to miss all the valuable information on fungicide resistance covered by our panel of industry experts.',
		COPY_MainCalloutPast:
			'Watch a recording of the Myths + Facts on Fungicide Resistance Webinar to gain valuable information on the differences between herbicide and fungicide resistance, what we have learned from other situations and best management practices to keep resistance at bay.',
		COPY_BottomCalloutContent:
			'<h1>Seed To Succeed</h1><p>The Seed to Succeed Webinar tackles InVigor<sup>®</sup> hybrid canola research, new approaches and seeding practices to optimize your canola seeding approach. </p>',
		COPY_ShareEmailSubject:
			'BASF%20%7C%20Register%20for%20the%20Myths%20+%20Facts%20on%20Resistance%20Webinar.',
		COPY_ShareEmailBody:
			'Hi%20There%2C%0A%0ASomeone%20you%20know%20just%20registered%20for%20the%20Myths%20+%20Facts%20on%20Fungicide%20Resistance%20Webinar%20and%20they%20thought%20you%20might%20want%20to%20join%20them%20in%20learning%20about%20fungicide%20resistance%20from%20industry%20experts.%20The%20webinar%20will%20take%20place%20on%20Wednesday,%20March%2024th,%202021%20at%209:00%20AM%20MST.%20Please%20visit%20agsolutions.ca/webinar%20to%20register.%20You’ll%20also%20earn%20one%20Continuing%20Education%20Unit%20(CEU)%20credit%20for%20attending. %0A%0AWe%20hope%20to%20see%20you%20there.',
		COPY_ShareTwitter:
			'Got%20plans%20for%20March%2024th?%20Now%20you%20do.%20Join%20the%20Myths%20+%20Facts%20on%20Fungicide%20Resistance%20Webinar%20to%20learn%20more%20on%20fungicide%20resistance%20and%20have%20your%20specific%20questions%20answered%20by%20our%20panel%20of%20experts.%20Register%20now:%20https://bit.ly/2PoTWEI%20%23BASFWebinars2021%20%23WestCdnAg',
		COPY_QADetails:
			'<h3>Host: Shaun Haney</h3><div><b>Presenters:</b></div><div><b>Colleen Redlick</b> | Technical Marketing Specialist – Fungicides | BASF Canada</div><div><b>Kelly Turkington</b> | Plant Pathologist | Agriculture and Agri-Food Canada – Lacombe</div><div><b>Duane Rathmann</b> | Technical Service Representative – South Minnesota | BASF USA</div>',
		STYLE_BottomCalloutTextColor: '#000000',
		STYLE_BottomCalloutBackgroundColor: '#ede9ea',
		RES_WebinarLink: 'https://kenna.adobeconnect.com/invigorresults',
		RES_Presentation: true,
		RES_VideoLink: 'Qu5FRtTykfA',
		RES_BottomCalloutLink: null,
	},
	{
		CONFIG_Legacy: false,
		CONFIG_QA: true,
		CONFIG_CampaignCode: '33FCC7BF-8057-477B-B7E0-B377124D10C9', // this is actually occurrenceCode??
		CONFIG_EventCode: '9826C840-7023-42D6-953B-EB33461F58B2',
		CONFIG_Name: 'Results by the Bushel Webinar',
		CONFIG_Status: 'Past',
		CONFIG_EpochStartTime: 1605628800,
		CONFIG_TopNavName: 'Results by the Bushel Webinar',
		CONFIG_Link: '/results',
		CONFIG_EarnCredit: true,
		CONFIG_BottomCallout: false,
		COPY_Details:
			'<p>Canola has a history rooted in Canadian innovation and will continue to increase its potential into the future. As a high-value crop, we know your search for new solutions continues so that you can effectively manage the most important challenges you are faced with in your area and on your farm. InVigor<sup>®</sup> hybrid canola shares this passion and has been a part of Canadian innovation for over 24 years. Our solutions come in many forms, including an extensive crop protection portfolio, industry-leading genetics, hybrid attributes, patented traits and supportive data, so that you can make the best decisions to address your individual needs. The Results by the Bushel Webinar will feature a panel of grower speakers who will each discuss what results mean to them and how they work to achieve them. When you register, you’ll also have the chance to pre-submit your questions to the panel to be answered at the end of the webinar.</p><p>Date: <b>November 17, 2020</b></p>\r\n <p>Time: <b>9:00 AM (MST)</b></p>\r\n<p>Location:<b> Online</b></p>\r\n<p>You’ll hear from:</p>   <p>Host</p><p><b>Shaun Haney</b> (Founder of Real Agriculture)</p><p>Panelists</p> <ul><li><strong>Harold Brown</strong> - BASF Technical Service Specialist</li><li><strong>Gunter Jochum</strong> – Blue Diamond Farms</li><li><strong>Terry Aberhart</strong> - Aberhart Farms</li></ul></p>',
		COPY_MainHeader: 'Results by the Bushel Webinar – Register now',
		COPY_MainCalloutBefore:
			'The Results by the Bushel Webinar airs on November 17, 2020 at 9:00 AM (MST). The webinar will feature a panel of InVigor<sup>®</sup> hybrid canola growers who will each discuss what results mean to them and how they work to achieve them.',
		COPY_MainCalloutDuring:
			'Join the Results by the Bushel Webinar to hear how InVigor® hybrid canola is constantly striving to provide growers with innovation and solutions that help deliver results to your farm.',
		COPY_MainCalloutAfter:
			'The Results by the Bushel Webinar may have ended, but no need to worry. We’ve recorded the webinar so you won’t miss a beat when it comes to innovation and solutions from InVigor® hybrid canola that deliver results.',
		COPY_MainCalloutPast:
			'Watch a recording of the Results by the Bushel Webinar to gain valuable information on how InVigor® hybrid canola provides growers with solutions to the most important challenges we are faced with in the canola production industry.',
		COPY_BottomCalloutContent:
			'<h1>Seed To Succeed</h1><p>The Seed to Succeed Webinar tackles InVigor<sup>®</sup> hybrid canola research, new approaches and seeding practices to optimize your canola seeding approach. </p>',
		COPY_ShareEmailSubject:
			'BASF%20%7C%20Register%20for%20the%20Results%20by%20the%20Bushel%20Webinar.',
		COPY_ShareEmailBody:
			"Hi%20There%2C%0A%0ASomeone%20you%20know%20just%20registered%20for%20the%20Results%20by%20the%20Bushel%20Webinar%20and%20they%20thought%20you%20might%20want%20to%20join%20them.%20The%20webinar%20will%20take%20place%20on%20November%2017th,%202020%20at%209:00%20AM%20MST%20(Alberta)%20%20or%2010%20AM%20CST%20(Saskatchewan%20and%20Manitoba).%20Please%20visit%20agsolutions.ca/webinar%20to%20register,%20so%20you%20can%20join%20the%20conversation%20on%20InVigorCanola.%20You'll%20also%20earn%20one%20CCA%20and%20one%20CCS%20CEU%20credit%20just%20for%20attending.%0A%0AWe%20Hope%20to%20see%20you%20there!",
		COPY_ShareTwitter:
			'I%20just%20signed%20up%20for%20the%20Results%20by%20the%20Bushel%20Webinar%20where%20I%E2%80%99ll%20be%20hearing%20from%20experts%20and%20growers%20on%20how%20InVigor%20hybrid%20canola%20helps%20deliver%20results.%20You%20can%20join%20me%20on%20November%2017%20when%20you%20register%20here:%20https://bit.ly/3jjqh9i%20%23BASFwebinars2020',
		COPY_QADetails:
			'<h3>Host: Shaun Haney</h3><div><b>Presenters:</b></div><div><b>Harold Brown</b> | BASF Technical Service Specialist</div><div><b>Gunter Jochum</b> | Blue Diamond Farms</div><div><b>Terry Aberhart</b> | Aberhart Farms</div>',
		STYLE_BottomCalloutTextColor: '#000000',
		STYLE_BottomCalloutBackgroundColor: '#ede9ea',
		RES_WebinarLink: 'https://kenna.adobeconnect.com/invigorresults',
		RES_Presentation: true,
		RES_VideoLink: 'jgjQFQ2WUeY',
		RES_BottomCalloutLink: null,
	},
	{
		CONFIG_Legacy: false,
		CONFIG_QA: true,
		CONFIG_CampaignCode: 'DC5E95E8-24BF-4D3B-99D7-046FCCB67D10', // this is actually occurrenceCode??
		CONFIG_EventCode: '8773FD02-A13B-407A-8F4C-5D7EF45D29DC',
		CONFIG_Name: 'Put the Boot to Clubroot',
		CONFIG_Status: 'Past',
		CONFIG_EpochStartTime: 1586358000,
		CONFIG_TopNavName: 'Put the Boot to Clubroot',
		CONFIG_Link: '/clubroot',
		CONFIG_EarnCredit: true,
		CONFIG_BottomCallout: false,
		COPY_Details:
			' Managing clubroot in canola populations is a constant battle. That’s because there’s no silver bullet when it comes to controlling the disease. As our understanding of clubroot is constantly evolving, the conversation around the disease needs to keep up. The Put the Boot to Clubroot Webinar will have three panel members explore their own unique knowledge of the disease, while also discussing a number of topics that will aid in its management. Register now for your chance to submit specific questions you’d like to see addressed during the Q&A session at the end of the webinar.</p><p>Date: <b>April 8<sup>th</sup>, 2020</b></p>\r\n <p>Time: <b>Alberta and Saskatchewan: 9:00 AM (MST) | Manitoba: 10:00 AM (CST)</b></p>\r\n<p>Who: <b>Sponsored by InVigor<sup>®</sup> hybrid canola</b></p>\r\n   <p>Hosted by <b>Shaun Haney</b> - Founder of Real Agriculture</p>\r\n     <p>You’ll also hear from: <ul><li><strong>Leighton Blashko </strong>- BASF Technical Service Specialist</li><li><strong>Stephen Strelkov</strong> - University of Alberta Professor</li><li><strong>Scott Keller</strong> - Keller Farms, Alberta</li></ul></p>',
		COPY_MainHeader: 'Put the Boot to Clubroot Webinar – Register now',
		COPY_MainCalloutBefore:
			'Put the Boot to Clubroot Webinar airs on April 8th, 2020 at 9:00 AM MST (Alberta and Saskatchewan) or 10 AM CST (Manitoba). The webinar will feature insights on clubroot from three panel members who bring their own unique knowledge and experience to the table.',
		COPY_MainCalloutDuring:
			'Join the Put the Boot to Clubroot Webinar to gain the latest insights on a multi-faceted approach to your fight against clubroot; learn all about evolving nomenclature and how to use integrated strategies to your advantage. The webinar will not only add to your knowledge, but you will also be eligible to earn one CCA and one CCSC CEU credit just for attending.',
		COPY_MainCalloutAfter:
			'The Put the Boot to Clubroot Webinar may have ended, but no need to worry. We’ve recorded the webinar, so you won’t miss what the three panelists had to say about clubroot, and the evolving practices used to manage the disease. You will also be eligible to earn one CCA and one CCSC CEU credit just for attending. ',
		COPY_MainCalloutPast:
			'Watch a recording of the Put the Boot to Clubroot Webinar to gain valuable information on the growing knowledge surrounding clubroot and its management practices. Learn all about clubroot-resistant genetics, the rationale behind the evolving nomenclature and a number of other topics that aid in the management of the disease. You’ll also earn one CCA and one CCSC CEU credit just for attending.',
		COPY_BottomCalloutContent:
			'<h1>Seed To Succeed</h1><p>The Seed to Succeed Webinar tackles InVigor<sup>®</sup> hybrid canola research, new approaches and seeding practices to optimize your canola seeding approach. </p>',
		COPY_ShareEmailSubject:
			'BASF%3A%20Register%20for%20Put%20the%20Boot%20to%20Clubroot%20Webinar',
		COPY_ShareEmailBody:
			'Someone%20you%20know%20just%20registered%20for%20the%20Put%20the%20Boot%20to%20Clubroot%20Webinar%20and%20they%20want%20you%20to%20join%20them.%20The%20webinar%20will%20take%20place%20on%20April%208th,%202020%20at%209:00%20a.m.%20MST%20(Alberta%20and%20Saskatchewan)%20or%2010%20AM%20CST%20(Manitoba).%20Please%20visit%20agsolutions.ca/webinar%20to%20register,%20so%20you%20can%20join%20the%20Conversation%20on%20Clubroot.%20You%E2%80%99ll%20also%20earn%20one%20CCA%20credit%20and%20one%20CCSC%20CEU%20credit%20just%20for%20attending.',
		COPY_ShareTwitter:
			'I%20just%20signed%20up%20for%20the%20%23ClubrootWebinar%20where%20I%E2%80%99ll%20be%20hearing%20three%20panelists%20discuss%20the%20evolution%20of%20clubroot%20and%20its%20management%20practices.%20You%20can%20join%20me%20on%20April%208th%20when%20you%20register%20here:%20https://bit.ly/3jjqh9i%20%23BASFwebinars2020',
		COPY_QADetails:
			'<h3>Host: Shaun Haney</h3><div><b>Presenters:</b></div><div><b>Leighton Blashko</b> | BASF Technical Service Specialist</div><div><b>Stephen Strelkov </b> | University of Alberta Professor</div><div><b>Scott Keller </b> | Keller Farms, Alberta</div>',
		STYLE_BottomCalloutTextColor: '#000000',
		STYLE_BottomCalloutBackgroundColor: '#ede9ea',
		RES_WebinarLink: 'https://kenna.adobeconnect.com/clubroot',
		RES_Presentation: true,
		RES_VideoLink: 'oWoX3iW58rM',
		RES_BottomCalloutLink: null,
	},
	{
		CONFIG_Legacy: false,
		CONFIG_QA: true,
		CONFIG_CampaignCode: 'BAA53EDA-9C67-42E2-9958-6E9775AB7D4D', // this is actually occurrenceCode??
		CONFIG_EventCode: '76CD0A73-03DE-43CE-BC21-D264E6DF3900',
		CONFIG_Name: 'Precision Decision Webinar',
		CONFIG_Status: 'Past',
		CONFIG_EpochStartTime: 1582644600,
		CONFIG_TopNavName: 'Precision Decision',
		CONFIG_Link: '/hrm',
		CONFIG_EarnCredit: true,
		CONFIG_BottomCallout: false,
		COPY_Details:
			'Managing herbicide resistance in Canada takes a multi-faceted approach. The more tools and solutions in your back pocket, the better—whether it’s using management practices such as herbicide layering or new precision technologies. During the Precision Decision 2020 Herbicide Resistance Management Webinar, three industry-leading panel members will discuss how existing herbicide management practices can be used with incoming technologies to combat herbicide resistance. Register now and be sure to submit specific questions you’d like the panel to address during the Q&A session at the end of the webinar.</p><p>Tune in <b>Tuesday, February 25<sup>th</sup> at 8:30 a.m. MST</b></p>\r\n    <p>Hosted by <b>Shaun Haney</b> - Founder of Real Agriculture</p>\r\n     <p>Hear from industry experts: <ul><li><strong>Warren Bills</strong>&nbsp;Senior Commercialization Manager and Regional Lead North Americas - xarvio<sup>®</sup> Digital Farming Solutions</li><li><strong>Dr. Breanne Tidemann</strong>&nbsp;Research Scientist - Agriculture and Agri-Food Canada</li><li><strong>Andrew Reid</strong>&nbsp;Technical Service Specialist - BASF Canada</li></ul></p>',
		COPY_MainHeader:
			'Precision Decision 2020 Herbicide Resistance Management Webinar - Register now.',
		COPY_MainCalloutBefore:
			'The Precision Decision 2020 Herbicide Resistance Management Webinar airs on February 25th, 2020 at 8:30 a.m. MST. Register for your chance to hear from three industry-leading panel members. The webinar will cover how to effectively use a multi-faceted approach against herbicide resistance.',
		COPY_MainCalloutDuring:
			'Join the Precision Decision 2020 Herbicide Resistance Management Webinar to gain the latest insights on a multi-faceted approach to your fight against herbicide resistance; find out how you can use herbicide layering and precision technology to your advantage. The webinar will not only add to your knowledge, but you will also be eligible to earn a CCA/CCSC credit just for attending.',
		COPY_MainCalloutAfter:
			'The Precision Decision 2020 Herbicide Resistance Management Webinar may have ended, but no need to worry. We’ve recorded the webinar, so you can watch or re-watch to find out what the three industry-leading experts had to say about using a multi-faceted approach to managing herbicide resistance. There’s still an opportunity to earn a CCA/CCSC credit while you’re at it.',
		COPY_MainCalloutPast:
			'Watch the recording of the Precision Decision 2020 Herbicide Resistance Management Webinar. Learn from the experts as they discuss a multi-faceted approach to herbicide resistance management, including the use of herbicide layering and precision technology. You can also earn a CCA/CCSC credit from watching the webinar.',
		COPY_BottomCalloutContent:
			'<h1>Seed To Succeed</h1><p>The Seed to Succeed Webinar tackles InVigor<sup>®</sup> hybrid canola research, new approaches and seeding practices to optimize your canola seeding approach. </p>',
		COPY_ShareEmailSubject:
			'BASF%3A%20Register%20for%20the%20Precision%20Decision%20Webinar',
		COPY_ShareEmailBody:
			'Someone%20you%20know%20just%20registered%20for%20the%20Precision%20Decision%202020%20Herbicide%20Resistance%20Management%20Webinar%E2%80%94and%20they%20want%20you%20to%20join%20them.%20The%20webinar%20will%20take%20place%20on%20February%2025th,%202020%20at%208:30%20a.m.%20MST.%20Please%20visit%20agsolutions.ca/webinar%20to%20register,%20so%20you%20can%20learn%20how%20to%20use%20a%20multi-faceted%20approach%20to%20herbicide%20resistance%20management.%20You%E2%80%99ll%20also%20earn%20a%20CCA%20credit%20when%20you%20attend.%20%0A%0AWe%20hope%20to%20see%20you%20there.%0A%20',
		COPY_ShareTwitter:
			'I%20just%20signed%20up%20for%20the%20%23PrecisionDecisionWebinar%20and%20I%E2%80%99m%20looking%20forward%20to%20hearing%20the%20panelists%20discuss%20a%20multi-faceted%20approach%20to%20herbicide%20resistance%20management.%20You%20can%20join%20me%20on%20February%2025th%20when%20you%20register%20here:%20https://bit.ly/2v3HBLU%20%23BASFwebinars2020',
		COPY_QADetails:
			'<h3>Host: Shaun Haney</h3><div><b>Presenters:</b></div><div><b>Warren Bills</b> | Senior Commercialization Manager and Regional Lead North Americas - xarvio<sup>®</sup> Digital Farming Solution</div><div><b>Dr. Breanne Tidemann</b> | Research Scientist - Agriculture and Agri-Food Canada</div><div><b>Andrew Reid</b> | Technical Service Specialist - BASF Canada</div>',
		STYLE_BottomCalloutTextColor: '#000000',
		STYLE_BottomCalloutBackgroundColor: '#ede9ea',
		RES_WebinarLink: 'https://kenna.adobeconnect.com/precisiondecision',
		RES_Presentation: true,
		RES_VideoLink: 'VGTmvXCWouo',
		RES_BottomCalloutLink: null,
	},
	{
		CONFIG_Legacy: false,
		CONFIG_QA: true,
		CONFIG_CampaignCode: '6B8948DF-45C1-4958-9ECA-5FE1FBD25607',
		CONFIG_EventCode: 'E0EA1600-9F05-41FD-AA38-BF0AE56C2D71',
		CONFIG_Name: 'Seed to Succeed Webinar',
		CONFIG_Status: 'Past',
		CONFIG_EpochStartTime: 1580316300,
		CONFIG_TopNavName: 'Seed To Succeed',
		CONFIG_Link: '/seed',
		CONFIG_EarnCredit: true,
		CONFIG_BottomCallout: false,
		COPY_Details:
			'<p>What began as a crop developed by a collaborative group of scientists from Agriculture and Agri-Food Canada and the University of Manitoba, has grown into one of Canada’s most profitable crops. We are constantly improving the genetics of the crop, but with the Canola Council of Canada’s average yield goal of 52 bu/ac by 2025, it’s time we bring your canola seeding program into the golden age. The Seed to Succeed Webinar is gathering three industry-leading panel members who will share how agronomic research can maximize the potential of your InVigor<sup>®</sup> hybrid canola seeding practices. Growers who register will also have the opportunity to pre-submit their questions to the panel members to be answered at the end of the webinar.</p><p>When: <b>9:45 - 10:45 AM (MST) - January 29<sup>th</sup>, 2020.</b></p>\r\n    <p>Where: <b>Live and online at FarmTech.</b></p>\r\n    <p>Who: <b>Sponsored by InVigor RATE.</b></p>\r\n    <p>Hosted by <b>Shaun Haney</b> (Founder of Real Agriculture)</p>\r\n    <p>Including panel members: <ul><li><strong>Rob MacDonald</strong>&nbsp;(BASF, Manager of Agronomic Excellence Team)</li><li><strong>Murray Hartman</strong>&nbsp;(Independent Canola Specialist)</li><li><strong>Matt Gosling</strong>&nbsp;(Owner of Premium Ag Services)</li></ul></p>',
		COPY_MainHeader:
			'The Seed to Succeed Webinar – Sponsored by InVigor RATE.',
		COPY_MainCalloutBefore:
			'The Seed to Succeed Webinar airs on January 29th. Register for your chance to hear from three industry-leading panel members. The webinar will cover InVigor® hybrid canola research, new approaches and seeding practices you can implement on your farm.',
		COPY_MainCalloutDuring:
			'Join the Seed to Succeed Webinar to gain the latest insights on seeding practices you can use to maximize your InVigor® hybrid canola. The webinar will not only pay off in the field, but you will also be eligible to earn a CEU credit just for attending.',
		COPY_MainCalloutAfter:
			'The Seed to Succeed Webinar has ended. You now have the opportunity to watch the recording if you missed it (or re-watch it if you enjoyed it that much). Don’t miss out on InVigor® hybrid canola research, new approaches and seeding practices that can benefit your crop, while also earning a CEU credit.',
		COPY_MainCalloutPast:
			'The Seed to Succeed Webinar was recorded live at FarmTech this year. Watch now to hear about target plant populations, factors that affect crop survivability, seed sizes and how to incorporate these into your seeding plans for 2020. The webinar not only includes insights from three industry-leading experts, but also gives you the chance to earn a CEU credit just for watching.',
		COPY_BottomCalloutContent:
			'<h1>Seed To Succeed</h1><p>The Seed to Succeed Webinar tackles InVigor<sup>®</sup> hybrid canola research, new approaches and seeding practices to optimize your canola seeding approach. </p>',
		COPY_ShareEmailSubject:
			'BASF%3A%20Register%20for%20the%20Seed%20to%20Succeed%20Webinar',
		COPY_ShareEmailBody:
			'It%20seems%20someone%20wants%20you%20to%20join%20us%20for%20the%20Seed%20to%20Succeed%20Webinar%20on%20January%2029th%2C%20at%209%3A45%20AM%20(MST).%20Please%20visit%20https%3A%2F%2Fwebinar.basf-agsolutions.ca%2Fseed%20to%20register%20for%20the%20webinar%20that%20will%20expose%20you%20to%20InVigor%C2%AE%20hybrid%20canola%20research%2C%20new%20approaches%20and%20seeding%20practices%20you%20can%20implement%20on%20your%20farm.%20You%E2%80%99ll%20also%20earn%20one%20CEU%20credit%20when%20you%20attend%20the%20Seed%20to%20Succeed%20Webinar.%0A%0ASee%20you%20there!',
		COPY_ShareTwitter:
			'I%E2%80%99m%20attending%20the%20%23SeedToSucceedWebinar!%20Join%20me%20on%20January%2029th%20to%20hear%20all%20about%20InVigor%20hybrid%20canola%20research%2C%20new%20approaches%20and%20seeding%20practices%20to%20optimize%20your%20operation.%20Register%20here%3A%20https%3A%2F%2Fbit.ly%2F36R4Nur%20%23FarmTech2020%20%23WestCdnAg',
		COPY_QADetails:
			'<h3>Host: Shaun Haney</h3><div><b>Presenters:</b></div><div><b>Rob MacDonald</b> | BASF, Manager of Agronomic Excellence Team</div><div><b>Murray Hartman</b> | Independent Canola Specialist</div><div><b>Matt Gosling</b> | Owner of Premium Ag Services</div>',
		STYLE_BottomCalloutTextColor: '#000000',
		STYLE_BottomCalloutBackgroundColor: '#ede9ea',
		RES_WebinarLink: 'https://kenna.adobeconnect.com/seedtosucceed',
		RES_Presentation: true,
		RES_VideoLink: 'ABhjZ6XxZcs',
		RES_BottomCalloutLink: null,
	},
	{
		CONFIG_Legacy: true,
		CONFIG_QA: true,
		CONFIG_CampaignCode: 'CMP-AOMI-B8EKXT',
		CONFIG_EventCode: 'E-AOMI-BAMSH6',
		CONFIG_Name: 'Protect Your Profits Fusarium Webinar',
		CONFIG_Status: 'Past',
		CONFIG_EpochStartTime: 1551366000,
		CONFIG_TopNavName: 'Fusarium Head Blight',
		CONFIG_Link: '/register',
		CONFIG_EarnCredit: true,
		CONFIG_BottomCallout: true,
		COPY_MainHeader:
			'Protect Your Profits Fusarium Webinar. Register and save your spot!',
		COPY_Details:
			'<p>Don’t let Fusarium impact your bottom line. The Protect Your Profits Fusarium Webinar has gathered industry-leading experts to discuss current trends and tips on preventing and managing fusarium head blight. Three cereal experts from Western Canada will be bringing in new information and insight on managing this disease., so make sure to ask them questions about your concerns. You’ll even have the option to submit your questions beforehand below.</p>\r\n    <p>The webinar kicks off on: <b>Thursday, February 28&zwnj;th at 8:00am MDT *</p>\r\n    <p><b>* Alberta – 8:00 am | Manitoba &amp; Saskatchewan – 9:00 am | Ontario &amp; Quebec – 10:00 am </b></p>\r\n    <p>Hosted by <b>Shaun Haney</b>, Founder of Real Agriculture.</p>\r\n    <p>You’ll also hear from: <ul><li><b>Dr. Michael Harding:</b>&nbsp;Research Scientist, Plant Pathology Alberta Agriculture and Forestry</li><li><b>Bruce Mackinnon:</b>&nbsp;Technical Service Specialist - BASF Canada</li><li><b>Manitoba Cereal Grower</b></li></ul></p>',
		COPY_MainCalloutBefore:
			'Save your spot and register for the Fusarium Head Blight event of the season and hear from the industry leading experts about the latest trends in managing Fusarium. You’ll be eligible to earn a CEU credit for attending the webinar.',
		COPY_MainCalloutDuring:
			'Join the Fusarium Head Blight event of the season and hear from the industry leading experts about the latest trends in managing Fusarium. You’ll be eligible to earn a CEU credit for attending the webinar.',
		COPY_MainCalloutAfter:
			'A recording of the Fusarium Head Blight webinar will be available shortly. You’ll hear from the industry leading experts about the latest trends in managing Fusarium. You’ll still be eligible to earn a CEU credit after watching the recording.',
		COPY_MainCalloutPast:
			'Learn all about managing Fusarium and best practices to control it to protect your profits, You’ll hear from the industry leading experts about the latest  trends in managing Fusarium. You’ll still be eligible to earn a CEU credit after watching the recording.',
		COPY_BottomCalloutContent:
			'<h2>Caramba Fungicide</h2><p>For preventative protection against late leaf disease and fusarium</p><ul><li>Proven protection against Fusarium</li><li>Effective control of later-season foliar diseases</li><li>Reduced deoxynivaelenol (DON) contamination to preserve grade quality</li></ul>',
		COPY_ShareEmailSubject:
			'BASF%20%7C%20Protect%20Your%20Profits%20Fusarium%20Webinar',
		COPY_ShareEmailBody:
			'Something%20worth%20watching!%20A%20replay%20of%20BASF%27s%20Protect%20Your%20Profits%20Fusarium%20Webinar.%0APlease%20visit%20www.agsolutions.ca%2Fwebinar%20to%20learn%20more',
		COPY_ShareTwitter:
			'Something%20worth%20watching!%20A%20replay%20of%20BASF%27s%20Protect%20Your%20Profits%20Fusarium%20Webinar.%20https%3A%2F%2Ftinyurl.com%2Fy5hrd3ks%0A%23Fusarium%20%23CanadaAg%20%23AlbertaAg%20%23ManitobaAg%20%23BASF%20%23DiseaseControl',
		COPY_QADetails:
			'<h3>Host: Shaun Haney</h3><div><b>Presenters:</b></div><div><b>Dr. Michael Harding</b> | Research Scientist - Plant Pathology - Alberta Agriculture & Forestry</div><div><b>Bruce Mackinnon</b> | Technical Service Specialist - BASF Canada</div><div><b>Maurice Vanwynsberghe</b> | Manitoba Cereal Grower</div>',
		STYLE_BottomCalloutTextColor: '#000000',
		STYLE_BottomCalloutBackgroundColor: '#ede9ea',
		RES_WebinarLink: 'https://kenna.adobeconnect.com/fhbwebinar/',
		RES_Presentation: true,
		RES_VideoLink: 'vCpYRkZoEGM',
		RES_BottomCalloutLink: null,
	},
	{
		CONFIG_Legacy: true,
		CONFIG_QA: true,
		CONFIG_CampaignCode: 'CMP-AOMI-B9AL9R',
		CONFIG_EventCode: 'E-AOMI-BBKM4U',
		CONFIG_Name: '2019 Herbicide Resistance Management',
		CONFIG_Status: 'Past',
		CONFIG_EpochStartTime: 1553090400,
		CONFIG_TopNavName: 'Herbicide Resistance Management',
		CONFIG_Link: '/hrm',
		CONFIG_EarnCredit: true,
		CONFIG_BottomCallout: true,
		COPY_MainHeader:
			'2019 Herbicide Resistance Management Webinar. Register now!',
		COPY_Details:
			'<p>With Canada ranking third among countries that have the highest number of herbicide resistant weeds, it’s a crucial time to hear from industry experts on the topic. <sup>1</sup> When you register for the 2019 Herbicide Resistance Management Webinar, you’ll gain insight on current resistance challenges. And you’ll hear how you can meet these challenges head-on with the Advanced Weed Control program. There’s also an opportunity to have your operation’s specific concerns addressed during the webinar; simply submit your questions beforehand on the registration page.<small> <sup>1</sup>International Survey of Herbicide Resistant Weeds, weedscience.org, 2019. </small></p>\r\n    <p>Tune in: <b>Wednesday, March 20<sup>th</sup>, 2019 at 8:00 am MDT*.</b></p>\r\n    <p><b>* Alberta &amp; Saskatchewan – 8:00 am | Manitoba – 9:00 am | Ontario &amp; Quebec – 10:00 am </b></p>\r\n    <p>Hosted by <b>Shaun Haney</b>, Founder of Real Agriculture.</p>\r\n    <p>Hear from industry experts: <ul><li><strong>Dr. Peter Sikkema:</strong>&nbsp;Professor, Field Crop Weed Management – University of Guelph, Ridgetown Campus</li><li><strong>Eric Johnson:</strong>&nbsp;Weed Scientist, Research Officer – University of Saskatchewan</li><li><strong>Andrew Reid:</strong>&nbsp;Technical Service Specialist – BASF Canada</li></ul></p>',
		COPY_MainCalloutBefore:
			'When you register for the 2019 Herbicide Resistance Management Webinar, you’ll gain insight on current resistance challenges and discover best practices to help meet them head-on. Not only will you be inspired to build an action plan against herbicide resistance, you will also earn one CCA credit when you attend the webinar.',
		COPY_MainCalloutDuring:
			'Join the 2019 Herbicide Resistance Management Webinar to gain insight on current resistance challenges and discover best practices to help meet them head-on. Not only will you be inspired to build an action plan against herbicide resistance, you will also earn one CCA credit when you attend the webinar.',
		COPY_MainCalloutAfter:
			"The 2019 Herbicide Resistance Management Webinar has ended, you'll have the opportunity to watch the replay soon and gain valuable insight on current resistance challenges and discover best practices to help meet them head‑on. Not only will you be inspired to build an action plan against herbicide resistance, you will also earn one CCA credit when you attend the webinar.",
		COPY_MainCalloutPast:
			'Watch now and gain valuable insights on current resistance challenges and discover best practices to help meet them head‑on. Not only will you be inspired to build an action plan against herbicide resistance, but you will also earn one CCA credit when you attend the webinar.',
		COPY_BottomCalloutContent:
			'<h2>The Advanced Weed Control Program</h2><p>The Advanced Weed Control Program from BASF takes the guesswork out of weed control and resistance management strategies. Follow the program from a complete solution in your peas, lentils, soybeans and&nbsp;corn.</p>',
		COPY_ShareEmailSubject:
			'BASF%20%7C%202019%20Herbicide%20Resistance%20Management%20Webinar',
		COPY_ShareEmailBody:
			'Something%20worth%20watching%21%20A%20replay%20of%20BASF%27s%202019%20Herbicide%20Resistance%20Management%20Webinar.%20Please%20visit%20www.agsolutions.ca/webinar%20to%20learn%20more.%20',
		COPY_ShareTwitter:
			"Something%20worth%20watching!%20A%20replay%20of%20BASF's%202019%20Herbicide%20Resistance%20Management%20Webinar.%20Visit%20agsolutions.ca/webinar%20to%20learn%20more.%20",
		COPY_QADetails:
			'<h3>Host: Shaun Haney</h3><div><b>Presenters:</b></div><div><b>Dr. Peter Sikkema</b> | Professor, Field Crop Weed Management - University of Guelph, Ridgetown Campus</div><div><b>Eric Johnson</b> | Weed Scientist, Research Officer - University of Saskatchewan</div><div><b>Andrew Reid</b> | Technical Service Specialist - BASF Canada</div>',
		STYLE_BottomCalloutTextColor: '#ffffff',
		STYLE_BottomCalloutBackgroundColor: '#161d2a',
		RES_WebinarLink: 'https://kenna.adobeconnect.com/hrmwebinar/',
		RES_Presentation: true,
		RES_VideoLink: 'kwl1oEFmsto',
		RES_BottomCalloutLink:
			'https://agro.basf.ca/advancedweedcontrol/?utm_source=HRM%20Webinar&utm_medium=HRM%20Webinar%20Callout&utm_campaign=2019HRM#/',
	},
	{
		CONFIG_Legacy: true,
		CONFIG_QA: true,
		CONFIG_CampaignCode: 'CMP-AOMI-B5PNNG',
		CONFIG_EventCode: 'E-AOMI-B8735R',
		CONFIG_Name: 'Set Up Soybeans For Success',
		CONFIG_Status: 'Past',
		CONFIG_EpochStartTime: 3,
		CONFIG_EarnCredit: true,
		COPY_MainCalloutPast:
			'A recording of the Set Up Soybeans For Success Webinar is now available. You’ll have the opportunity to learn best practices and the latest trends for growing soybeans from industry leading experts. In addition to gaining vital knowledge you’ll also qualify to earn one CCA CEU credit after watching the recording of the webinar.',
		COPY_ShareEmailSubject:
			'BASF%20%7C%20Set%20Up%20Soybeans%20For%20Success',
		COPY_ShareEmailBody:
			'This%20is%20something%20worth%20watching%3A%20A%20replay%20of%20the%20Set%20Up%20Soybeans%20For%20Success%20Webinar.%0A%0APlease%20visit%20www.agsolutions.ca/webinar%20to%20watch%20the%20webinar.%0A',
		COPY_ShareTwitter:
			'This%20is%20something%20worth%20watching%3A%20A%20replay%20of%20the%20Set%20Up%20Soybeans%20For%20Success,%20visit%20%20www.agsolutions.ca/webinar%20to%20view%20now.%20%23WestCdnAg%20%23Soybeans',
		COPY_QADetails:
			'<h3>Host: Shaun Haney</h3><div><b>Presenters:</b></div><div><b>Piran Cargeeg</b> | Product Development Manager, Inoculants - BASF Global</div><div><b>AJ Woodyard</b> | Technical Crop Production Specialist - BASF USA</div><div><b>Rob Miller</b> | Technical Development Manager - BASF Canada</div><div><b>A Canadian Soybean Grower</b> | Western Canada</div>',
		RES_Presentation: true,
		RES_VideoLink: 'DOk8BlLkdfU',
	},
	{
		CONFIG_Legacy: true,
		CONFIG_QA: false,
		CONFIG_CampaignCode: 'CMP-FPOL-AZPT9N',
		CONFIG_EventCode: 'E-AOMI-B2UH28',
		CONFIG_Name: '2018 Straight Cutting Canola',
		CONFIG_Status: 'Past',
		CONFIG_EpochStartTime: 2,
		CONFIG_EarnCredit: true,
		COPY_MainCalloutPast:
			'During the 2018 Straight Cutting Canola Webinar, industry leading experts across Canada shared their knowledge about the latest trends in straight cutting best practices and the benefits of using Heat LQ herbicide. The webinar is now available online for your convenience to watch anytime. And you can earn a CCA CEU credit for it.',
		COPY_ShareEmailSubject:
			'BASF%3A%20Watch%20a%20replay%20of%20the%202018%20Straight%20Cut%20Webinar',
		COPY_ShareEmailBody:
			'I%20just%20watched%20the%202018%20Straight%20Cutting%20Canola%20Webinar%20and%20thought%20you%E2%80%99d%20be%20interested%20too.%0A%0AVisit%20agsolutions.ca%2Fwebinar%20to%20learn%20more.',
		COPY_ShareTwitter:
			'This%20is%20something%20worth%20watching%3A%20A%20replay%20of%20the%202018%20%23StraightCutWebinar%2C%20visit%20%20www.agsolutions.ca%2Fwebinar%20to%20view%20now%2E%20%23WestCdnAg%20%23HeatLQ%20%23canola',
		COPY_QADetails: '',
		RES_Presentation: true,
		RES_VideoLink: 'ryoebGgYVM4',
	},
	{
		CONFIG_Legacy: true,
		CONFIG_QA: false,
		CONFIG_CampaignCode: 'CMP-RMAR-AXSPA8',
		CONFIG_EventCode: 'E-AVAI-AXSPCU',
		CONFIG_Name: '2018 Herbicide Resistance Management',
		CONFIG_Status: 'Past',
		CONFIG_EpochStartTime: 1,
		CONFIG_EarnCredit: false,
		COPY_MainCalloutPast:
			'Just when you thought you heard all there is to know about herbicide resistance. Well, there’s more. During the 2018 Herbicide Resistance Management Webinar, industry experts worldwide shared important information that could bring value to your yield this year.',
		COPY_ShareEmailSubject: '',
		COPY_ShareEmailBody: '',
		COPY_ShareTwitter: '',
		COPY_QADetails: '',
		RES_Presentation: true,
		RES_VideoLink: '7rv1zZyMtYI',
	},
];

export { pages, currentWebinar };
