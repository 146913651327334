import React, { Component } from 'react';

import QAQuestion from './QAQuestion/QAQuestion';

import './QACallout.css';
import '../Callout.css';

var isMounted = false;

class QACallout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            selectedIndex: -1
        }

        this.myRef = React.createRef();
    }

    componentDidMount() {
        isMounted = true;
        setTimeout(() => {if (isMounted && this.props.campaigncode === this.parseQuery("campaign")) this.setState({expanded: true}, () => window.scrollTo(0, this.myRef.current.offsetTop))}, 1000);
    }

    componentWillUnmount() {
        isMounted = false;
    }

    parseQuery = (param) => {
        let returnVal = null;

        if (window.location.search.length > 0) {
            (window.location.search[0] === '?' ? window.location.search.substr(1) : window.location.search).split('&').forEach((pair) => {
                if (pair.split('=')[0] === param)
                    returnVal = (pair.split('=').length > 1 ? pair.split('=')[1] : "true");
            })
        }
        return returnVal;
    }

    render() {
        return (
            <div className="QACallout" ref={this.myRef}>
                <div className="Callout reverse">
                    <div className="Callout-Image"><img src={this.props.image} alt="QA"/></div>
                    <div className="Callout-Content">
                        <h2>{this.props.name}</h2>
                        <div className="QA-Details" dangerouslySetInnerHTML={{__html: this.props.details}} />
                        <button className="qa-button" data-tealium-category="links" data-tealium-action="click pagebox-link" data-tealium-value={"questions and answers "+this.props.name} data-tealium-type="navigation" type="button" onClick={() => this.setState({expanded: !this.state.expanded})}>{this.state.expanded ? "Close section" : "Questions and answers"}</button>
                    </div>
                </div>
                <div className={`QA-Question-Section ${this.state.expanded ? "expand" : ""}`} >
                    <div className="Callout-disclaimer">Certain views, opinions and guidance provided or expressed herein may not necessarily reflect the view or position of BASF.  To learn more about our Disclaimer please visit  <a href='https://www.basf.com/ca/en/legal/disclaimer.html'>here</a></div>
                    {this.props.qa.map((q,i) => <QAQuestion key={i} author={q.Author} question={q.Question} answer={q.Answer} expanded={this.state.selectedIndex === i} handleClick={() => this.state.selectedIndex === i ? this.setState({selectedIndex: -1}) : this.setState({selectedIndex: i})} />)}
                </div>
            </div>
        );
    }
}

export default QACallout;