import React, { Component } from 'react';

import TopNavItem from './TopNavItem/TopNavItem';
import BASFLogo from '../../images/topnav/BASF_Logo.svg';
import MenuExpand from '../../images/topnav/MenuExpand.png';
import MenuClose from '../../images/topnav/MenuClose.png';

import './TopNav.css';

class TopNav extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: this.props.activeIndex,
            menuExpanded: false
        }
    }

    parseQuery = (param) => {
        let returnVal = null;

        if (window.location.search.length > 0) {
            (window.location.search[0] === '?' ? window.location.search.substr(1) : window.location.search).split('&').map((pair) => {
                if (pair.split('=')[0] === param)
                    returnVal = (pair.split('=').length > 1 ? pair.split('=')[1] : "true");
                return true;
            })
        }

        return returnVal;
    }

    setActivePage = (index) => {
        this.setState({activeIndex: index, menuExpanded: false});
    }

    render() {

        const activePageCount = this.props.pages.filter((page) => (page.CONFIG_Status === "Active" && (page.CONFIG_EpochStartTime + 7200 > ((new Date()).getTime() / 1000)))).length;

        return (
            <div className="TopNav">
                <div className="LogoBar">
                    <a href="https://agro.basf.ca/"><div className="BASFLogo" style={{backgroundImage: `url(${BASFLogo})`}}></div></a>
                </div>
                <div className={`TopNavItemBar ${this.props.stickyTopNav ? "stickyNav" : ""}`}>
                    <div className="MenuExpand TopNavItem" style={{backgroundImage: `url(${this.state.menuExpanded ? MenuClose : MenuExpand})`}} onClick={() => this.setState({menuExpanded: !this.state.menuExpanded})} />
                    <div className={`TopNavItems ${this.state.menuExpanded ? "" : "hiddenNav"}`}>
{                       !(this.parseQuery("media") === "true")
                        ?
                        <React.Fragment>
                        <TopNavItem index={-1} name="Home" link="/" isActive={this.state.activeIndex === -1} setActivePage={() => this.setActivePage(-1)}/>
{                       this.props.pages.sort((a, b) => a.CONFIG_EpochStartTime - b.CONFIG_EpochStartTime).map((page, i) => {
                            if (page.CONFIG_Status === "Active" && (page.CONFIG_EpochStartTime + 7200 > ((new Date()).getTime() / 1000)))
                                return <TopNavItem key={i} index={i} name={activePageCount === 1 ? (Math.abs(page.CONFIG_EpochStartTime - ((new Date()).getTime() / 1000)) < 7200 ? "Join Now" : "Register") : page.CONFIG_TopNavName} link={page.CONFIG_Link} isActive={this.state.activeIndex === i} setActivePage={(i) => this.setActivePage(i)}/>;
                                //return <TopNavItem key={i} index={i} name={activePageCount === 1 ? "Register" : page.CONFIG_TopNavName} link={page.CONFIG_Link} isActive={this.state.activeIndex === i} setActivePage={(i) => this.setActivePage(i)}/>;
                            
                            return null;
                        })
}                       <TopNavItem index={-2} name="Q&A" link="/qa" isActive={this.state.activeIndex === -2} setActivePage={() => this.setActivePage(-2)}/>
                        </React.Fragment>
                        :
                        <TopNavItem index={-99} name="Home" link={window.location.pathname} search={window.location.search} isActive={this.state.activeIndex === -99} setActivePage={() => this.setActivePage(-99)}/>
}                   </div>
                </div>
            </div>
        );
    }
}

export default TopNav;