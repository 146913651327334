import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Youtube from 'react-youtube';
import Questionaire from '../../Questionaire/Questionaire';

import TwitterImg from '../../../images/share/twitter.png';
import EmailImg from '../../../images/share/email.png';

import './PastCallout.css';
import '../Callout.css';

var isMounted = false;

class PastCallout extends Component {

    constructor(props){
        super(props);

        this.state = {
            showVideo: false,
            finished: false,
            submitted: false
        };
        this.presentations = require.context('../../../resources/Presentations');
        this.myRef = React.createRef();
        this.myRef2 = React.createRef();
    }

    componentDidMount() {
        isMounted = true;
        setTimeout(() => {if (isMounted && !this.props.calloutscroll && this.props.campaigncode === this.props.pincampaigncode) this.setState({showVideo: true}, () => window.scrollTo(0, this.myRef.current.offsetTop))}, 2000);
        setTimeout(() => {if (isMounted && this.props.calloutscroll && this.props.campaigncode === this.props.pincampaigncode) window.scrollTo(0, this.myRef2.current.offsetTop-50)}, 1500);
    }

    componentWillUnmount() {
        isMounted = false;
    }

    renderVideo = () => {
        let webinarName = this.props.name;
        if (webinarName.length < 7 || webinarName.slice(-7) !== "Webinar") {
            webinarName += " Webinar";
        }

        if (this.state.showVideo) {
            if (this.state.finished) {
                if (this.state.submitted) {
                    return (
                        <div className="SubmittedContainer">
                            <h2>Thank you! Your information has been successfully submitted.</h2>
                            <div>
                                <div className="ShareContainer">
                                    <div className="ShareContainer-Child"><a href={`mailto:?Subject=${this.props.share_emailsubject}&body=${this.props.share_emailbody}`}><img src={EmailImg} alt="email" /></a></div>
                                    <p className="ShareContainer-Child">Know someone else who is interested in learning more about this webinar?<br />Click on the mail icon to share this event with them.</p>
                                </div>
                                <div className="ShareContainer">
                                    <div className="ShareContainer-Child"><a href={`https://twitter.com/intent/tweet?text=${this.props.share_twitter}`} target="_blank" rel="noopener noreferrer"><img src={TwitterImg} alt="twitter" /></a></div>
                                    <p className="ShareContainer-Child">Share via Twitter<br />Click on the share icon to share on Twitter.</p>
                                </div>
                            </div>
                        </div>
                    )
                }
                return (
                    <div className="WatchedContainer">
                        <h2>Thank you for watching</h2>
                        {this.props.credit ? <React.Fragment><p>Please enter your information below to earn one CCA/CCSC credit.</p><Questionaire isLegacy={this.props.isLegacy} attended={true} campaign_code={this.props.campaigncode} event_code={this.props.eventcode} includeSurvey={false} handleSubmit={() => this.setState({submitted: true})}/></React.Fragment> : null}
                    </div>
                )
            }
            return (
                <div className="VideoContainer" ref={this.myRef}>
                    <h2>Webinar video</h2>
                    {this.props.credit ? <p>{`After watching the entire recording on the ${webinarName} page, you'll be able to enter your information to earn one CCA/CCSC credit.`}</p> : null}
                    <Youtube className="WebinarVideo" videoId={this.props.video} opts={{playerVars: {autoplay: 1, controls: (this.props.credit ? 0 : 1)}}} onEnd={() => this.setState({finished: true})}/>
                </div>
            )
        }

        return null;
    }

    render() {
        return(
            <React.Fragment>
                <div className={"PastCallout Callout"} style={{backgroundColor: this.props.backgroundcolor}}ref={this.myRef2}>
                    <div className="Callout-Image"><img src={this.props.image} alt={this.props.name} /></div>
                    <div className="Callout-Content" >
                        <h2>{this.props.name}</h2>
                        <p dangerouslySetInnerHTML={{__html: this.props.text}}></p>
                        {this.props.video !== null ? <button data-tealium-category="video" data-tealium-action="click video-button" data-tealium-value={"play video "+ this.props.name} data-tealium-type="action" className="Past-Callout-Button" type="button" onClick={() => this.setState({showVideo: !this.state.showVideo})}>{this.state.showVideo ? "Close Section" : "Watch the video"}</button> : null}
                        {this.props.presentation ? <a data-tealium-category="download" data-tealium-action="click download" data-tealium-value={"download presentation||"+this.presentations(`./${this.props.eventcode}.pdf`)} data-tealium-type="download" href={this.presentations(`./${this.props.eventcode}.pdf`)} target="_blank" rel="noopener noreferrer"><button className="Past-Callout-Button" type="button">Download presentation</button></a> : null}
                        {this.props.qa ? <Link to={{ pathname: "/qa", search: `campaign=${this.props.campaigncode}`}}><button className="Past-Callout-Button" data-tealium-category="links" data-tealium-action="click pagebox-link" data-tealium-value={"open QA"+this.props.campaigncode} data-tealium-type="navigation" type="button">Q&A</button></Link> : null}
                    </div>
                </div>
                {this.renderVideo()}
            </React.Fragment>
        )
    }
}
export default PastCallout;