import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './ActiveCallout.css';
import '../Callout.css';

class ActiveCallout extends Component {

    constructor(props){
        super(props);

        this.state = {
            countdown: this.props.page.CONFIG_EpochStartTime - ((new Date()).getTime() / 1000)
        }

        this.heroImages = require.context('../../../resources/Images/Hero');
    }

    componentDidMount() {
        this.WebinarCountdown = setInterval(() => {
            this.setState({countdown: this.props.page.CONFIG_EpochStartTime - ((new Date()).getTime() / 1000)})
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.WebinarCountdown);
    }
    
    render() {
        let main_copy, background, timing, registerButton, datestring;
        let startDate = (new Date(this.props.page.CONFIG_EpochStartTime * 1000));
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        /* During */
        if (Math.abs(this.state.countdown) < 7200) {
            main_copy = this.props.page.COPY_MainCalloutDuring;
            background = "#f79924";
            timing = "during";
            datestring = <h3>Happening now:</h3>;
        }
        /* Before */
        else if (this.state.countdown > 0) {
            main_copy = this.props.page.COPY_MainCalloutBefore;
            background = (this.props.index % 2 === 0 ? "#f3f3f3" : "#d3d3d3");
            timing = "before";
            datestring = <h3>{this.props.index === 0 ? "Next webinar:" : "Upcoming webinar:"} <i>{`${monthNames[startDate.getMonth()]} ${startDate.getDate()}, ${startDate.getFullYear()}`}</i></h3>
        }
        /* After */
        else {
            main_copy = this.props.page.COPY_MainCalloutAfter;
            background = (this.props.index % 2 === 0 ? "#f3f3f3" : "#d3d3d3");
            timing = "after";
            datestring = <h3>Recent webinar:</h3>
        }

        switch (timing) {
            case "before": registerButton = <Link to={this.props.page.CONFIG_Link}><button className="reg-btn before" type="button">Register now</button></Link>; break;
            case "during": registerButton = <Link to={this.props.page.CONFIG_Link}><button className="reg-btn during" type="button">JOIN NOW</button></Link>; break;
            default: registerButton = null;
        }

        return(
            <div className={"ActiveCallout Callout reverse"}>
                <div className="Callout-Image"><img src={this.heroImages(`./${this.props.page.CONFIG_EventCode}.jpg`)} alt={this.props.page.CONFIG_Name}/></div>
                <div className="Callout-Content" style={{backgroundColor: background}}>
                    {datestring}
                    <h2>{this.props.page.CONFIG_Name}</h2>
                    <p dangerouslySetInnerHTML={{__html: main_copy}}></p>
                    {registerButton}
                </div>
            </div>
        )
    }
}
export default ActiveCallout;