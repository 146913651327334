import React, { Component } from 'react';
// import ReactGA from 'react-ga';
// import TagManager from 'react-gtm-module';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import 'isomorphic-fetch';
import 'babel-polyfill';

import TopNav from './components/TopNav/TopNav';
import HomePage from './components/Page/HomePage/HomePage';
import WebinarPage from './components/Page/WebinarPage/WebinarPage';
import QAPage from './components/Page/QAPage/QAPage';
import Footer from './components/Footer/Footer';
import RegLookupPage from './components/Page/RegLookupPage/RegLookupPage';
import { pages } from './constants/constants';

import './Webinar.css';

class Webinar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			stickyTopNav: window.pageYOffset >= 80,
			pages: [],
		};
	}

	componentWillMount() {
		// TagManager.initialize({ gtmId: 'GTM-MZTRXRD' });
		// ReactGA.initialize('UA-3480885-34');
		this.setState({ pages });
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = () => {
		if (window.pageYOffset >= 80 && !this.state.stickyTopNav) {
			this.setState({ stickyTopNav: true });
		} else if (window.pageYOffset < 80 && this.state.stickyTopNav) {
			this.setState({ stickyTopNav: false });
		}
	};

	render() {
		return (
			<div className='Webinar'>
				<BrowserRouter basename={process.env.PUBLIC_URL}>
					<React.Fragment>
						<TopNav
							pages={this.state.pages}
							stickyTopNav={this.state.stickyTopNav}
						/>
						<Switch>
							{this.state.pages
								.sort(
									(a, b) =>
										a.CONFIG_EpochStartTime -
										b.CONFIG_EpochStartTime
								)
								.map((page, i) => {
									return page.CONFIG_Status === 'Active' &&
										page.CONFIG_EpochStartTime + 7200 >
											new Date().getTime() / 1000 ? (
										<Route
											path={page.CONFIG_Link}
											render={() => (
												<WebinarPage
													stickyTopNav={
														this.state.stickyTopNav
													}
													page={page}
												/>
											)}
											key={i}
										/>
									) : null;
								})}{' '}
							<Route
								exact
								path='/qa'
								render={() => (
									<QAPage
										name='Q&A'
										stickyTopNav={this.state.stickyTopNav}
										pages={this.state.pages}
									/>
								)}
							/>
							<Route
								exact
								path='/'
								render={() => (
									<HomePage
										name='Home'
										stickyTopNav={this.state.stickyTopNav}
										pages={this.state.pages}
									/>
								)}
							/>
							<Route
								exact
								path='/reg-lookup'
								render={() => (
									<RegLookupPage
										name='Registration Lookup'
										stickyTopNav={this.state.stickyTopNav}
									/>
								)}
							/>
							<Redirect to='/' />
						</Switch>
						<Footer />
					</React.Fragment>
				</BrowserRouter>
			</div>
		);
	}
}

export default Webinar;
