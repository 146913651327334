import React, { Component } from 'react';
import { currentWebinar } from '../../../constants/constants';

import { baseURL } from '../../../constants/api';

import './RegLookupPage.css';

class RegLookupPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			results: [],
			email: '',
		};
	}

	handleSubmit = (e) => {
		e.preventDefault();

		fetch(
			`${baseURL}/getreginfo?email=${this.state.email}&eventcode=${currentWebinar.CONFIG_EventCode}`,
			{
				method: 'GET',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/json',
				},
				referrer: 'no-referrer',
			}
		).then((response) =>
			response.json().then((response) => {
				this.setState({ results: response.data });
			})
		);
	};

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render() {
		return (
			<div
				className={`RegLookupPage ${
					this.props.stickyTopNav ? 'stickyPageMargin' : ''
				}`}
			>
				<h2>Registration Lookup Page</h2>

				<h3 className='current'>
					Current Webinar: {currentWebinar.CONFIG_Name}
				</h3>

				<p>
					Please enter the registration email address below to get the
					webinar link and registration details for this user.
				</p>

				<div className='lookup-form'>
					<input
						className='email-input'
						type='email'
						name='email'
						placeholder='Please enter an email address...'
						onChange={this.handleChange}
					/>

					<button
						name='submit'
						className='email-submit'
						value={this.state.email}
						onClick={this.handleSubmit}
					>
						Submit
					</button>
				</div>

				<div className='lookup-results' key='results-container'>
					{this.state.results.map((item) => (
						<React.Fragment key={item.RegistrationCode}>
							<h3>Lookup Results for: {this.state.email}</h3>
							<p>Date Registered On: {item.RegistrationDate}</p>
							<p>First Name: {item.FirstNameUser}</p>
							<p>Last Name: {item.LastNameUser}</p>
							<p>
								URL: {item.url || 'http://webinarurlhere.com'}
							</p>
						</React.Fragment>
					))}
				</div>
			</div>
		);
	}
}

export default RegLookupPage;
