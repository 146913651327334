import React, { Component } from 'react';
//import ReactGA from 'react-ga';

import { baseURL } from '../../../constants/api';

import YouTubeImg from '../../../images/callouts/youtube.png';
import TwitterImg from '../../../images/callouts/twitter.png';
import Mic from '../../../images/callouts/mic.png';
import Rec from '../../../images/callouts/rec.png';
import {Timeline} from 'react-twitter-widgets';

import ActiveCallout from '../../Callout/ActiveCallout/ActiveCallout';
import PastCallout from '../../Callout/PastCallout/PastCallout';

import './HomePage.css';
import '../../Callout/Callout.css';


class HomePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dest_campaign: null,
            dest_callout:false
        }

        this.heroImages = require.context('../../../resources/Images/Hero');
        
    }

    parseQuery = (param) => {
        let returnVal = null;

        if (window.location.search.length > 0) {
            (window.location.search[0] === '?' ? window.location.search.substr(1) : window.location.search).split('&').map((pair) => {
                if (pair.split('=')[0] === param)
                    returnVal = (pair.split('=').length > 1 ? pair.split('=')[1] : "true");
                return true;
            })
        }
        return returnVal;
    }

    componentWillMount() {
        if (RegExp('[A-Za-z0-9]{7,8}').test(this.parseQuery("pin")) && RegExp('[0-9]+').test(this.parseQuery("rtid"))) {
            fetch(`${baseURL}/displayuser?pin=${this.parseQuery("pin")}&rtid=${this.parseQuery("rtid")}`, {
                method: "GET",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                },
                referrer: "no-referrer",
            })
            .then(response => response.json().then(response => {
                if (response.data) {
                    this.setState({
                        dest_campaign: response.data.CampaignCode
                    })
                }
            }));
        }
        // Legacy campaign codes like "CMP-AOMI-B8EKXT"
        else if (RegExp('CMP-[A-Z]{4}-[A-Z0-9]{6}').test(this.parseQuery("campaign"))) {
            this.setState({dest_campaign: this.parseQuery("campaign")})
        }
        // New campaign codes are UUIDs
        else if (RegExp('[0-9A-f]{8}-[0-9A-f]{4}-[0-9A-f]{4}-[0-9A-f]{4}-[0-9A-f]{12}').test(this.parseQuery("campaign"))) {
            this.setState({ dest_campaign: this.parseQuery("campaign") });
        }
        // for scrolling to past callout based on campaign code
        if(this.parseQuery("callout")) {
            this.setState({
                dest_callout: true
            })        
        }

    }

    componentDidMount() {
        document.title = "BASF Webinars - Latest Agricultural Trends From Industry Experts";
      //  ReactGA.pageview("BASF Webinars - Latest Agricultural Trends From Industry Experts");

        window.scrollTo(0, 0);
    }

    renderHeroImage = () => {
        let main_webinar = (this.props.pages.filter((page) => (page.CONFIG_Status === "Active" && (page.CONFIG_EpochStartTime + 7200 > ((new Date()).getTime() / 1000)))).sort((a, b) => a.CONFIG_EpochStartTime - b.CONFIG_EpochStartTime)).concat(this.props.pages.filter((page) => (page.CONFIG_Status === "Active" && (page.CONFIG_EpochStartTime + 7200 <= ((new Date()).getTime() / 1000)))).sort((a, b) => b.CONFIG_EpochStartTime - a.CONFIG_EpochStartTime))[0];
        if (!main_webinar)
            main_webinar = (this.props.pages.filter((page) => (page.CONFIG_Status === "Past" && (page.CONFIG_EpochStartTime + 7200 > ((new Date()).getTime() / 1000)))).sort((a, b) => a.CONFIG_EpochStartTime - b.CONFIG_EpochStartTime)).concat(this.props.pages.filter((page) => (page.CONFIG_Status === "Past" && (page.CONFIG_EpochStartTime + 7200 <= ((new Date()).getTime() / 1000)))).sort((a, b) => b.CONFIG_EpochStartTime - a.CONFIG_EpochStartTime))[0];
        
        if (main_webinar)
            return <img className="HeroImage" src={this.heroImages(`./${main_webinar.CONFIG_EventCode}.jpg`)} alt={main_webinar.CONFIG_TopNavName} />;

        return null;
    }

    render() {
        return (
            <div className={`HomePage ${this.props.stickyTopNav ? "stickyPageMargin" : ""}`}>
                {this.renderHeroImage()}
                <div className="Intro">
                    <h1>Welcome to BASF exclusive&nbsp;webinars</h1>
                    <p>Stay up to date with the latest trends in the industry from the leading experts. We made it easier for you to view all the exclusive webinars in one place! From on-demand webinars to live webinars, this is your online platform for gaining boundless knowledge on the industry's latest innovations and best&nbsp;practices.</p>
                </div>
                <div className="ActiveCallouts">
    {                   (this.props.pages.filter((page) => (page.CONFIG_Status === "Active" && (page.CONFIG_EpochStartTime + 7200 > ((new Date()).getTime() / 1000)))).sort((a, b) => a.CONFIG_EpochStartTime - b.CONFIG_EpochStartTime)).concat(this.props.pages.filter((page) => (page.CONFIG_Status === "Active" && (page.CONFIG_EpochStartTime + 7200 <= ((new Date()).getTime() / 1000)))).sort((a, b) => b.CONFIG_EpochStartTime - a.CONFIG_EpochStartTime)).map((page, i) => {
                        return <ActiveCallout key={i} page={page} index={i} />
                    })
    }               </div>
                <div className="Callout reverse white-on-blue PastHeader">
                    <div className="Callout-Content PastHeader-Images">
                        <div className="PastWebinarImages-Wrapper">
                            <div className="PastWebinarImages">
                                <img src={Rec} alt="Rec" />
                                <img src={Mic} alt="Mic" />
                            </div>
                        </div>
                    </div>
                    <div className="Callout-Content PastHeader-Text">
                        <h2>On-Demand Webinars</h2>
                        <p>Missed a webinar? No problem! You still have the opportunity to watch the webinar recordings for your convenience at anytime You'll still be eligible to earn a CEU credit after watching it. Make sure you submit your information in the form after you finish watching the webinar recording video.</p>
                    </div>
                </div>
                <div className="PastCallouts">
    {                   (this.props.pages.filter((page) => (page.CONFIG_Status === "Past"))).sort((a, b) => b.CONFIG_EpochStartTime - a.CONFIG_EpochStartTime).map((page, i) => {
                        return <PastCallout isLegacy={page.CONFIG_Legacy} campaigncode={page.CONFIG_CampaignCode} pincampaigncode={this.state.dest_campaign} calloutscroll={this.state.dest_callout} eventcode={page.CONFIG_EventCode} name={page.CONFIG_Name} text={page.COPY_MainCalloutPast} image={this.heroImages(`./${page.CONFIG_EventCode}.jpg`)} presentation={page.RES_Presentation} qa={page.CONFIG_QA} video={page.RES_VideoLink} credit={page.CONFIG_EarnCredit} share_emailsubject={page.COPY_ShareEmailSubject} share_emailbody={page.COPY_ShareEmailBody} share_twitter={page.COPY_ShareTwitter} backgroundcolor={i % 2 === 0 ? "#f5f5f5" : "#ffffff"} key={i} />
                    })
    }                </div>
                <div className="HomeCallouts flex">
                    {/*<div className="Callout-Half flex stacked">
                        <div className="Callout-Half white-on-green">
                            <div className="Callout-Content">
                                <h2>Talk to us. We're here for&nbsp;you.</h2>
                                <p>Want to chat? One of our live agents is ready to answer your&nbsp;questions.</p>
                            </div>
                        </div>
                        */}
                        <div className="Callout-Half darkgrey-on-grey">
                            <div className="Callout-Content">
                                <h2>Got any questions?</h2>
                                <p>Call our team of experts at 1-877-371-BASF (2273) to reach AgSolutions<sup>®</sup> Customer&nbsp;Care.</p>
                                <a data-tealium-category="link" data-tealium-action="click footer-link" data-tealium-value="twitter||https://twitter.com/basfagsolutions" data-tealium-type=""  href="https://twitter.com/basfagsolutions" target="_blank" rel="noopener noreferrer"><img className="socialmedia-img" src={TwitterImg} alt="Twitter" /></a>
                                <a data-tealium-category="link" data-tealium-action="click footer-link" data-tealium-value="youtube||http://www.youtube.com/user/BASFAgSolutions" data-tealium-type="" href="http://www.youtube.com/user/BASFAgSolutions" target="_blank" rel="noopener noreferrer"><img className="socialmedia-img" src={YouTubeImg} alt="YouTube" /></a>
                            </div>
                        </div>
                    {/*</div>*/}
                    <div className="Callout-Half white-on-grey">
                        <div className="Callout-Content">
                            <h2>Follow us on Twitter.</h2>
                            <p>Follow @BASFAgSolutions on Twitter for helpful information and to learn more about BASF fungicides and crop protection&nbsp;products.</p>
                            <div className="Twitter-Wrapper"><Timeline dataSource={{sourceType: 'profile', screenName: 'BASFAgSolutions'}} options={{username: '‎BASFAgSolutions', height: '305px', theme: 'dark', tweetLimit: 5}} /></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomePage;