import React from 'react';

import './Modal.css';

const Modal = (props) => 
    <div className="Modal">
        <div className="Modal-Background" />
        <div className="Modal-Content">
            <div className="close-button" onClick={props.handleClose}>&#x2715;</div>
            <h3>{props.title}</h3>
            <p>{props.message}</p>
            <div className="okay-button-wrapper">
                <button className="okay-button" type="button" onClick={props.handleClose}>OKAY</button>
            </div>
        </div>
    </div>

export default Modal;